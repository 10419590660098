import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const delete_cancelation_reason = createAsyncThunk("delete_cancelation_reason", async ({ vehicle_id }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/cancellationReasons/${vehicle_id}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }

        const result = await response.json();
        return result

    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const delete_cancelation_detail = createSlice({
    name: "delete_cancelation_detail",
    initialState: {
        data: "",
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_delete_cancelation_State: (state) => {
            state.data = "";
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(delete_cancelation_reason.pending, (state) => {
            state.loading = true
        })
            .addCase(delete_cancelation_reason.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isError = false
                state.loading = false
                state.data = action.payload
            })
            .addCase(delete_cancelation_reason.rejected, (state, action) => {
                state.isError = true
                state.isSuccess = false
                state.error = action.payload
                state.loading = false
            })
    }
})
export const { clear_delete_cancelation_State } = delete_cancelation_detail.actions;
export default delete_cancelation_detail.reducer