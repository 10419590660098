import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import view_selected_booking_detail_slice from '../features/Bookings/view_selected_booking_detail_slice';
import { view_selected_booking_details } from '../features/Bookings/view_selected_booking_detail_slice'
import Moment from "react-moment";
import Loader from '../Components/Loader';
import { clear_pickup_location_State } from '../features/Bookings/get_pick_up_location_slice';
import { clear_drop_location_State } from '../features/Bookings/get_place_name_by_coordinates_slice';
import Wrapper from '../Components/Wrapper/wrapper'
import { useNavigate, useLocation } from 'react-router-dom';
import './booking.css'
import { IoIosArrowBack } from "react-icons/io";
import Rating from '../Components/Rating/rating';
const BookingDetails = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { booking_id } = location.state ? location.state : location;
    const booking_details = useSelector((state) => { return state.booking_detail })
    const dispatch = useDispatch()
    useEffect(() => {
        return () => {
            dispatch(clear_pickup_location_State())
            dispatch(clear_drop_location_State())
        };
    }, [])

    useEffect(() => {
        dispatch(view_selected_booking_details({ booking_id }))
    }, [])


    return (
        <Wrapper>
            {booking_details?.loading ?
                <Loader /> :
                <div className="booking_detrails_wrapper">
                    <div className='booking_header'>
                        <span onClick={() => navigate("/booking")} style={{ cursor: "pointer" }} title="Back To Bookings"><IoIosArrowBack size={20} /></span>
                        <h3>Booking ID:</h3> <span className='status_badge '>{booking_id}</span> <span className='status_badge ms-auto'>Status : {booking_details?.data?.data?.bookingDetails?.booking_status} </span>
                    </div>
                    <div className='booking_details'>
                        <h5>Rider’s Details</h5>
                        <ul className='border-bottom pb-3'>
                            <li>
                                <strong>Riders</strong>
                                <div className='d-flex align-items-center gap-2 round_image'>
                                    <img src={booking_details?.data?.data?.riderDetails?.passengerImage} alt="" /> <p className='mb-0'>{booking_details?.data?.data?.riderDetails?.firstNamename + booking_details?.data?.data?.riderDetails?.lastNamee}</p>
                                </div>
                            </li>
                            <li>
                                <strong>Booking For</strong>
                                <p className='m-0'>Self</p>
                            </li>
                            <li>
                                <strong>Booking Date</strong>
                                <p className='m-0'><Moment format=' Do MMMM YYYY, h:mm:ss a'>
                                    {booking_details?.data?.data?.riderDetails?.bookingDate}
                                </Moment></p>
                            </li>
                            {booking_details?.data?.data?.bookingDetails?.cancelledBy != null &&
                                <li>
                                    <strong>Cancelled By </strong>
                                    <p className='m-0'>Rider</p>
                                </li>}
                            {booking_details?.data?.data?.bookingDetails?.cancellationReason != null && <li>
                                <strong>Cancelled Reason</strong>
                                <p className='m-0'>{booking_details?.data?.data?.bookingDetails?.cancellationReason}</p>
                            </li>}


                        </ul>
                        <h5>Driver & Vehicle Details</h5>
                        <ul className='border-bottom pb-3'>
                            <li>
                                <strong>Driver</strong>
                                <div className='d-flex align-items-center gap-2 round_image'>
                                    <img src={booking_details?.data?.data?.driverDetails?.driverImage} alt="" /> <p className='mb-0'>{booking_details?.data?.data?.driverDetails?.firstName + booking_details?.data?.data?.driverDetails?.firstName}</p>
                                </div>
                            </li>
                            <li>
                                <strong>Vehicle Type</strong>
                                <p className='m-0'>{booking_details?.data?.data?.driverDetails?.vehicleType}</p>
                            </li>
                            <li>
                                <strong>Vehicle  Number</strong>
                                <p className='m-0'>{booking_details?.data?.data?.driverDetails?.vehileNumber}</p>
                            </li>


                        </ul>
                        <h5>Booking Details</h5>
                        <ul className='border-bottom pb-3'>
                            {booking_details?.data?.data?.bookingDetails?.preBookingFor != null && <li>
                                <strong>Pre Booking For</strong>
                                <p className='m-0'>{booking_details?.data?.data?.bookingDetails?.preBookingFor}</p>
                            </li>}
                            <li>
                                <strong>Pick Up</strong>
                                <p className='m-0'>{booking_details?.data?.data?.bookingDetails?.pickUpLocation}
                                </p>

                            </li>
                            <li>
                                <strong>Drop Off</strong>
                                <p className='m-0'>{booking_details?.data?.data?.bookingDetails?.dropOff}
                                </p>
                            </li>
                            {booking_details?.data?.data?.bookingDetails?.rideStartTime != null && <li>
                                <strong>Pick Up Time</strong>
                                <p className='m-0'>
                                    <Moment format=' Do MMMM YYYY, h:mm:ss a'>
                                        {booking_details?.data?.data?.bookingDetails?.rideStartTime}
                                    </Moment>
                                </p>
                            </li>}
                            {booking_details?.data?.data?.bookingDetails?.rideEndTime != null && <li>
                                <strong>Drop TIme</strong>
                                <p className='m-0'>
                                    <Moment format=' Do MMMM YYYY, h:mm:ss a'>
                                        {booking_details?.data?.data?.bookingDetails?.rideEndTime}
                                    </Moment></p>
                            </li>}
                            <li>
                                <strong>Kilometres</strong>
                                <p className='m-0'>{booking_details?.data?.data?.bookingDetails?.kiloMeters ? booking_details?.data?.data?.bookingDetails?.kiloMeters + " km" : 0}</p>
                            </li>

                        </ul>
                        <h5>Payment Details</h5>
                        <ul className='border-bottom pb-3'>
                            <li>
                                <strong>Payment Type</strong>
                                <p className='m-0'>{booking_details?.data?.data?.paymentDetails?.paymentType ? booking_details?.data?.data?.paymentDetails?.paymentType : "Not Available"}</p>
                            </li>
                            <li>
                                <strong>Total Amount</strong>
                                <p className='m-0'>{booking_details?.data?.data?.paymentDetails?.Amount ? "$" + booking_details?.data?.data?.paymentDetails?.Amount : "Not Available"}</p>
                            </li>


                        </ul>
                        {<h5>Ratings</h5>}
                        <ul>
                            {booking_details?.data?.data?.rideRatings?.commentGivenToDrive && <li>
                                <strong>Rider Comment</strong>
                                <p className='m-0'>{booking_details?.data?.data?.rideRatings?.commentGivenToDriver}</p>
                            </li>}
                            {booking_details?.data?.data?.rideRatings?.ratingGivenToDriver !== 0 && <li>
                                <strong>Rider Rating</strong>
                                <Rating value={booking_details?.data?.data?.rideRatings?.ratingGivenToDriver ? booking_details?.data?.data?.rideRatings?.ratingGivenToDriver : 0} />
                            </li>}

                            {booking_details?.data?.data?.rideRatings?.commentGivenToPassenger && <li>
                                <strong>Driver Comment</strong>
                                <p className='m-0'>{booking_details?.data?.data?.rideRatings?.commentGivenToPassenger}</p>
                            </li>}
                            {booking_details?.data?.data?.rideRatings?.ratingGivenToPassenger !== 0 && <li>
                                <strong>Driver Rating</strong>
                                <Rating value={booking_details?.data?.data?.rideRatings?.ratingGivenToPassenger ? booking_details?.data?.data?.rideRatings?.ratingGivenToPassenger : 0} />
                            </li>}

                        </ul>
                    </div>
                </div>}
        </Wrapper>
    )
}

export default BookingDetails