import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from '../Button/button'
import { useDispatch, useSelector } from 'react-redux'
import { add_vehicle_data } from '../../features/VehicleType/addVehicleSlice'
import { toast } from "react-hot-toast";
import { clear_add_vehicle_State } from '../../features/VehicleType/addVehicleSlice'

const AddVehicleModal = ({ handleClose, get_all_vehicle }) => {
    const dispatch = useDispatch()
    const driver_data = useSelector((state) => { return state.add_vehicle })
    const [vehicle_type, setVehicle_type] = useState("")
    const [day_price, setDay_price] = useState(null)
    const [night_price, setNight_price] = useState(null)
    const [seat_capacity, setSeat_capacity] = useState(null)
    const [vehicleIcon, setVehicleIcon] = useState(null)

    useEffect(() => {
        if (driver_data?.isSuccess) {
            toast.success("Success")
            handleClose()
            get_all_vehicle()
            dispatch(clear_add_vehicle_State())
        }
    }, [driver_data])

    const handleSave = () => {
        if (vehicle_type !== "" || day_price !== null || night_price !== null || seat_capacity !== null || vehicleIcon !== null) {
            dispatch(add_vehicle_data({ vehicle_type, day_price, night_price, seat_capacity, vehicleIcon }))
        }
        else {
            toast.error("Fields cann't be empty !!")
        }
    }
    return (
        <Row className='m-0 form_wrapper'>
            <Col lg={6}>
                <div className='form-group mb-3'>
                    <label htmlFor="">Vehicle Type</label>
                    <select className="form-select" value={vehicle_type} onChange={(e) => setVehicle_type(e.target.value)}>
                        <option>Select</option>
                        <option value="Just-Go">Just-Go</option>
                        <option value="Electric Car">Electric-Car</option>
                        <option value="Limousine">Limousine</option>
                        <option value="Luxury">Luxury</option>
                        <option value="Taxi-4 Seat">Taxi-4 Seat</option>
                        <option value="Taxi-7 seat">Taxi-7 Seat</option>
                        <option value="Economy">Economy</option>
                    </select>
                </div>
            </Col>
            <Col lg={6}>
                <div className='form-group mb-3'>
                    <label htmlFor="">Price per KM / day</label>
                    <input type="number" placeholder='$4' className='form-control' onChange={(e) => setDay_price(e.target.value)} />
                </div>
            </Col>
            <Col lg={6}>
                <div className='form-group mb-3'>
                    <label htmlFor="">Price per KM / night</label>
                    <input type="number" placeholder='$10' className='form-control' onChange={(e) => setNight_price(e.target.value)} />
                </div>
            </Col>
            <Col lg={6}>
                <div className='form-group mb-3'>
                    <label htmlFor="">Seating capacity</label>
                    <input type="text" placeholder='4' className='form-control' onChange={(e) => setSeat_capacity(e.target.value)} />
                </div>
            </Col>
            <Col lg={12}>
                <div className='form-group mb-3'>
                    <label htmlFor="">Vehicle Icon</label>
                    <input type="file" placeholder='4' className='form-control' onChange={(e) => setVehicleIcon(e.target.files[0])} />
                </div>
            </Col>
            <Col lg={12} className='text-center mt-2'>
                <Button title="Cancel" className="outline_btn me-4" onClick={handleClose} />
                <Button title="Submit" className="theme_light" onClick={() => handleSave()} loading={driver_data?.loading} />
            </Col>
        </Row>
    )
}

export default AddVehicleModal