import React, { useState, useEffect } from 'react'
import DataTable from '../Table/dataTable'
import { useDispatch, useSelector } from 'react-redux'
import { view_driver_wallet_details } from '../../features/wallet/get_driver_wallet_detail_slice';
import ReactPaginate from 'react-paginate';
import NoData from '../Nodata/noData';
import { useNavigate } from 'react-router-dom';
const RiderWalletTab = ({ passenger }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const data = useSelector((state) => { return state.driver_passenger_wallet_details })
  console.log(data, "this is the whole waalet data")
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState(1)
  const [coloum, setColoum] = useState("No")
  const [passengerDetails, setPassengerDetails] = useState([])
  const [no_record_found, setNo_record_found] = useState(false)
  useEffect(() => {
    if (passenger) {
      dispatch(view_driver_wallet_details({ page, sort, coloum }))
    }
  }, [page, sort, coloum, passenger])

  useEffect(() => {
    setPassengerDetails(data?.data?.data)
    if (data?.data?.message === "no details found") {
      setNo_record_found(true)
    } else {
      setNo_record_found(false)
    }
  }, [data])

  const handlePageClick = (e) => {
    const NewPage = e.selected + 1;
    setPage(NewPage)
  }

  const header = [{ name: "Sr No" }, { name: "Rider Name" }, { name: "Phone Number" }, { name: "Email" }, { name: "Total Rides" }, { name: "Wallet" }, { name: "Action" }]
  const eyeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
    <path d="M10 10.5C11.933 10.5 13.5 8.933 13.5 7C13.5 5.067 11.933 3.5 10 3.5C8.067 3.5 6.5 5.067 6.5 7C6.5 8.933 8.067 10.5 10 10.5Z" stroke="#737373" />
    <path d="M17.958 5.934C18.346 6.406 18.54 6.641 18.54 7C18.54 7.359 18.346 7.594 17.958 8.066C16.538 9.79 13.406 13 9.77 13C6.134 13 3.002 9.79 1.582 8.066C1.194 7.594 1 7.359 1 7C1 6.641 1.194 6.406 1.582 5.934C3.002 4.21 6.134 1 9.77 1C13.406 1 16.538 4.21 17.958 5.934Z" stroke="#737373" />
  </svg>
  return (
    <div>
      <DataTable header={header} loading={data?.loading} setColoum={setColoum} setSort={setSort}>
        {
          passengerDetails?.map((all_details, index) => {
            return (
              <tr key={index}>
                <td valign="middle">{index + 1}</td>
                <td valign="middle"><div className='d-flex gap-2 align-items-center'>
                  <img src={all_details?.profile} alt="" />
                  {all_details?.firstName + " " + all_details?.lastName}
                </div>
                </td>
                <td valign="middle">{all_details?.mobile ? all_details?.mobile : "Not Available"}</td>
                <td valign="middle">{all_details?.email ? all_details?.email : "Not Available"}</td>
                <td valign="middle">{all_details?.totalCompletedRides ? all_details?.totalCompletedRides : 0}</td>
                <td valign="middle">{all_details?.remainingBalance ? all_details?.remainingBalance : 0}</td>
                <td valign="middle" title="View History" onClick={() => navigate("/wallet/walletHistory", { state: { user_id: all_details?._id, type: "passenger" } })}>{eyeIcon}</td>
              </tr>
            )

          })
        }

      </DataTable>
      <NoData data={no_record_found} />
      {!no_record_found && <div className='custom_paginations'>
        <p className='mb-0'></p>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={data?.data?.totalPages}
          previousLabel="previous"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>}
    </div>
  )
}

export default RiderWalletTab