
import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from 'react-bootstrap'
import Button from '../Button/button'
import JoditEditor from 'jodit-react';
import { useDispatch, useSelector } from "react-redux";
import { add_terms_and_condition } from "../../features/agreementContent/addTermsAndConditionSlice";
import { toast } from "react-hot-toast";
import { edit_terms_and_condition } from "../../features/agreementContent/edit_terms_and_condition_slice";
import { update_term_and_condition } from "../../features/agreementContent/update_term_and_condition_slice";
import { get_all_terms_and_condition } from "../../features/agreementContent/get_all_terms_and_condition_slice";
import { clear_update_term_and_condition_state } from "../../features/agreementContent/update_term_and_condition_slice";
const PrivacyModal = ({ title, handleClose, handleCloseEdit, id }) => {
  const dispatch = useDispatch()
  const data = useSelector((state) => { return state.add_terms_condition })
  const edit_data = useSelector((state) => { return state.edit_terms_condition })
  const updated_data = useSelector((state) => { return state.update_term })
  const editor = useRef();
  const [content, setContent] = useState("");

  useEffect(() => {
    if (id !== undefined) {
      dispatch(edit_terms_and_condition({ id }))
    }
  }, [id])

  useEffect(() => {
    if (edit_data?.isSuccess) {
      setContent(edit_data?.data?.data?.agreementContent)
    }
  }, [id && edit_data])

  const handleSave = () => {
    if (!id) {
      dispatch(add_terms_and_condition({ title, content }))
    }
    else {
      dispatch(update_term_and_condition({ id, content }))
    }
  }

  useEffect(() => {
    if (data?.isSuccess) {
      handleClose()
      toast.success("Success")
    }
  }, [data])

  useEffect(() => {
    if (updated_data?.isSuccess) {
      toast.success("Updated Successfully")
      setContent(" ")
      handleCloseEdit()
      dispatch(clear_update_term_and_condition_state())
      dispatch(get_all_terms_and_condition({ title }))
    }
  }, [updated_data])

  return (
    <div>
      <Row className='m-0 form_wrapper'>
        <Col lg={12}>
          <div className='form-group mb-3'>
            <JoditEditor
              ref={editor}
              value={content}
              onChange={(newContent) => setContent(newContent)}
            />
          </div>
        </Col>

        <Col lg={12} className='text-center mt-2'>
        <Button title="Cancel" className="outline_btn me-4" onClick={() => id !== undefined ? handleCloseEdit() : handleClose()} />
          <Button title="Submit" className="theme_light" onClick={() => handleSave()} loading={data?.loading ? data?.loading : updated_data?.loading} />
        </Col>
      </Row>

    </div>
  )
}

export default PrivacyModal