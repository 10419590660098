import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const get_single_question = createAsyncThunk("get_single_question", async ({ id }, thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/getFaq?id=${id}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            throw new Error(errorMessage.message);
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const getSingleQuestion = createSlice({
    name: "getSingleQuestion",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_get_single_question_state: (state) => {
            state.data = []
            state.isError = false
            state.isSuccess = false
            state.loading = false
            state.error = null
            return state
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_single_question.pending, (state) => {
                state.loading = true
            })
            .addCase(get_single_question.fulfilled, (state, action) => {
                state.data = action.payload
                state.isError = false
                state.isSuccess = true
                state.loading = false
            })
            .addCase(get_single_question.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const { clear_get_single_question_state } = getSingleQuestion.actions
export default getSingleQuestion.reducer