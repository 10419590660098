import { configureStore } from "@reduxjs/toolkit";
import LoginData from "../features/Login/loginSlice";
import forgetPassword from "../features/ForgetPassword/forgetPasswordSlice";
import reset_password from "../features/resetPassword/resetPasswordSlice";
import get_driver_details from "../features/Driver_details/driver_detail_slice";
import view_driver_documents from "../features/Driver_details/driver_veiw_documents_slice";
import verify_driver from "../features/Driver_details/driver_verify_slice";
import all_driver_data from "../features/AllDrivers/all_driver_slice";
import get_selected_driver_detail from "../features/AllDrivers/get_selected_driver_detail_slice";
import add_vehicle from "../features/VehicleType/addVehicleSlice";
import get_all_vehicles from "../features/VehicleType/getAllVehicleSlice";
import get_single_vehicle_detail from "../features/VehicleType/getSingleVehicleDataSlice";
import update_vehicle_type from "../features/VehicleType/updateVehicleTypeSlice";
import delete_selected_vehicle_detail from "../features/VehicleType/deleteSelectedVehicleSlice";
import add_cancelation_reason_detail from "../features/Cancellation/addCancelationReasonSlice";
import get_all_cancelation_reason from "../features/Cancellation/getAllCancelationReasonsSlice";
import delete_cancelation_detail from "../features/Cancellation/deleteCancelationReasonSlice";
import get_selected_reason_detail from "../features/Cancellation/getSelecteCancelationReasonSlice";
import update_cancelation from "../features/Cancellation/updateCancelationReasonSlice";
import all_currencies from "../features/all_currency/all_currency_slice";
import all_riders from "../features/Riders/rider_list_slice";
import activate_deactivate_driver from "../features/AllDrivers/activate_deactivate_driver_slice";
import all_bookings from "../features/Bookings/all_bookings_slice";
import view_booking_detail from "../features/Bookings/view_selected_booking_detail_slice";
import get_location_detail from "../features/Bookings/get_place_name_by_coordinates_slice";
import get_pik_up_location_detail from "../features/Bookings/get_pick_up_location_slice";
import dashboard_data from "../features/dashboard/dashboardSlice";
import change_password from "../features/changePassword/changePasswordSlice";
import dashboard_graph_data from "../features/dashboard/dashboardGraphDataSlice";
import update_currency from "../features/AllDrivers/update_slice";
import credit_and_debit from "../features/wallet/credit_debitSlice";
import viewDriverPassengerWalletDetails from "../features/wallet/get_passenger_driver_wallet_details";
import viewDriverWalletDetails from "../features/wallet/get_driver_wallet_detail_slice";
import viewSelectedDriverWalletDetail from "../features/wallet/get_selected_driver_passenger_detail_slice";
import addTermsAndCondition from "../features/agreementContent/addTermsAndConditionSlice";
import getAllTermsAndCondition from "../features/agreementContent/get_all_terms_and_condition_slice";
import editTermsAndCondition from "../features/agreementContent/edit_terms_and_condition_slice";
import updateTermAndCondition from "../features/agreementContent/update_term_and_condition_slice";
import addNewQuestion from "../features/FAQ's/add_new_question_slice";
import getAllQuestions from "../features/FAQ's/get_all_questions_slice";
import deleteQuestion from "../features/FAQ's/delete_question_slice";
import getSingleQuestion from "../features/FAQ's/get_single_question_slice";
import updateQuestion from "../features/FAQ's/update_question_slice";
import getAllDeleteRequests from "../features/deleteRequests/get_all_delete_requests_slice";
import deleteRequest from "../features/deleteRequests/delete_request_slice";
import update_driver_commission from "../features/AllDrivers/updateDriverCommison";
import get_commision from "../features/AllDrivers/driverCommissionSlice";
import update_all_driver_commission_status from "../features/AllDrivers/updateAllDriverCommissionSlice";

export const store = configureStore({
    reducer: {
        login: LoginData,
        forgetPassword: forgetPassword,
        resetPassword: reset_password,
        driver_requests: get_driver_details,
        driver_documents: view_driver_documents,
        verify_driver: verify_driver,
        all_drivers: all_driver_data,
        selected_driver_detail: get_selected_driver_detail,
        add_vehicle: add_vehicle,
        get_all_vehicles: get_all_vehicles,
        single_vehicle_details: get_single_vehicle_detail,
        update_vehicle_type: update_vehicle_type,
        delete_vehicle_detail: delete_selected_vehicle_detail,
        add_cancelation_reason: add_cancelation_reason_detail,
        all_cancelation_reason: get_all_cancelation_reason,
        delete_cancelation_data: delete_cancelation_detail,
        selected_reason_detail: get_selected_reason_detail,
        update_selected_cancelation: update_cancelation,
        all_currency: all_currencies,
        all_rider_list: all_riders,
        active_deactive_driver: activate_deactivate_driver,
        booking_list: all_bookings,
        booking_detail: view_booking_detail,
        get_location_data: get_location_detail,
        pik_up_location: get_pik_up_location_detail,
        dashboard_detail: dashboard_data,
        change_admin_password: change_password,
        dashboard_graph_details: dashboard_graph_data,
        update_currency_driver: update_currency,
        credit_debit_payment: credit_and_debit,
        driver_passenger_wallet_details: viewDriverPassengerWalletDetails,
        view_driver_wallet: viewDriverWalletDetails,
        selected_user_wallet_history: viewSelectedDriverWalletDetail,
        add_terms_condition: addTermsAndCondition,
        get_all_terms: getAllTermsAndCondition,
        edit_terms_condition: editTermsAndCondition,
        update_term: updateTermAndCondition,
        add_question: addNewQuestion,
        get_questions: getAllQuestions,
        delete_faq: deleteQuestion,
        get_single_FAQ: getSingleQuestion,
        update_faq: updateQuestion,
        get_all_delete_req: getAllDeleteRequests,
        del_req: deleteRequest,
        driver_commission: update_driver_commission,
        all_driver_commission: get_commision,
        all_commission_state: update_all_driver_commission_status
    },
})