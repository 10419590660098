import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from '../Button/button'
import { useDispatch, useSelector } from 'react-redux'
import { add_cancelation_reason } from '../../features/Cancellation/addCancelationReasonSlice'
import { toast } from "react-hot-toast";
import { clear_add_cancelation_State } from '../../features/Cancellation/addCancelationReasonSlice'

const ReasonModal = ({ handleClose,get_all_reasons_data }) => {
    const dispatch = useDispatch()
    const [type, setType] = useState("")
    const [reason, setReason] = useState("")
    const cancelation_result = useSelector((state) => { return state.add_cancelation_reason })

    const add_cancelation_reason_data = () => {
        if(type !== "" || reason !== ""){
            dispatch(add_cancelation_reason({type,reason}))
        }
        else{
            toast.error("Fields Can't Be Empty !!")
        }
            
       
    }

    useEffect(() => {
        if (cancelation_result?.isSuccess) {
            toast.success("cancelation Reason Saved Successfully !!")
            dispatch(clear_add_cancelation_State())
            get_all_reasons_data()
            handleClose()
            setType("")
            setReason("")
        }
    }, [cancelation_result])
    
    return (
        <Row className='m-0 form_wrapper'>
            <Col lg={12}>
                <div className='form-group mb-3'>
                    <label htmlFor="">User Type</label>
                    <select className="form-select" onChange={(e) => setType(e.target.value)}>
                        <option>Select</option>
                        <option value="driver">Driver</option>
                        <option value="passenger">Passenger</option>
                    </select>
                </div>
            </Col>

            <Col lg={12}>
                <div className='form-group mb-3'>
                    <label htmlFor="">Reason</label>
                    <textarea name="" className='form-control' id="" cols="30" rows="5" onChange={(e) => setReason(e.target.value)}></textarea>
                </div>
            </Col>

            <Col lg={12} className='text-center mt-2'>
                <Button title="Cancel" className="outline_btn me-4" onClick={handleClose} />
                <Button title="Submit" className="theme_light" loading={cancelation_result?.loading} onClick={() => add_cancelation_reason_data()} />
            </Col>
        </Row>
    )
}

export default ReasonModal