import React from 'react'
import Button from '../Button/button'
const ConfirmModal = ({handleClose,confirmMessage,delete_record,loading}) => {
  return (
    <div>
        <h4>{confirmMessage}</h4>
        <div className='text-center'>
        <Button title="Cancel"  className="outline_btn me-4" onClick={handleClose} />
                <Button title="Submit" className="theme_light" loading={loading} onClick={() => delete_record()}/> 
        </div>
    </div>
  )
}

export default ConfirmModal