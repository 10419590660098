import React, { useEffect, useState } from 'react'
import Wrapper from '../Components/Wrapper/wrapper'
import { Row, Col } from 'react-bootstrap'
import Card from '../Components/Card/card'
import './dashboard.css'
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from '../Components/Table/dataTable'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { get_dashboard_data } from '../features/dashboard/dashboardSlice'
import { useDispatch, useSelector } from 'react-redux'
import { get_dashboard_graph_data } from '../features/dashboard/dashboardGraphDataSlice'
import Loader from '../Components/Loader'
import NoData from '../Components/Nodata/noData'
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
      const navigate = useNavigate()
      const dispatch = useDispatch()
      const dashboard_card_data = useSelector((state) => { return state.dashboard_detail })
      const graph_data = useSelector((state) => { return state.dashboard_graph_details })
      const [year, setYear] = useState([])

      useEffect(() => {
            dispatch(get_dashboard_data())
            dispatch(get_dashboard_graph_data())
            years()
      }, [])

      const years = (startYear = 2023) => {
            let currentYear = new Date().getFullYear();
            let result = [];

            while (startYear <= currentYear) {
                  result.push(startYear++);
            }
            setYear(result)
      }

      return (
            <Wrapper title="Report">
                  {
                        graph_data?.loading ? (<Loader />)
                              :
                              (<div>
                                    <Row>
                                          <Col >
                                                <Card className="total_card">

                                                      <img src="/images/rides.png" alt="" />
                                                      <p>Total Rides</p>
                                                      <h4>{dashboard_card_data?.data?.totalCompletedRides ? dashboard_card_data?.data?.totalCompletedRides : 0}</h4>

                                                </Card>
                                          </Col>
                                          <Col onClick={() => navigate("/rider")} style={{ cursor: "pointer" }} title='View All Riders'>
                                                <Card className="total_card">

                                                      <img src="/images/cancelrides.png" alt="" />
                                                      <p>Total Riders</p>
                                                      <h4>{dashboard_card_data?.data?.totalPassengers ? dashboard_card_data?.data?.totalPassengers : 0}</h4>

                                                </Card>
                                          </Col>
                                          <Col onClick={() => navigate("/drivers")} style={{ cursor: "pointer" }} title='View All Drivers'>
                                                <Card className="total_card">

                                                      <img src="/images/activerides.png" alt="" />
                                                      <p>Total Drivers</p>
                                                      <h4>{dashboard_card_data?.data?.totalDrivers ? dashboard_card_data?.data?.totalDrivers : 0}</h4>

                                                </Card>
                                          </Col>
                                          <Col >
                                                <Card className="total_card">

                                                      <img src="/images/earnings.png" alt="" />
                                                      <p>Total Earning</p>
                                                      <h4>{dashboard_card_data?.data?.totalEarnings ? "$" + Number(dashboard_card_data?.data?.totalEarnings)?.toFixed(2) : 0}</h4>

                                                </Card>
                                          </Col>
                                          <Col >
                                                <Card className="total_card">

                                                      <img src="/images/commission.png" alt="" />
                                                      <p>Total Commission</p>
                                                      <h4>{dashboard_card_data?.data?.commission ? "$" + Number(dashboard_card_data?.data?.commission)?.toFixed(2) : 0}</h4>

                                                </Card>
                                          </Col>
                                    </Row>
                                    {/* Graphs */}
                                    {graph_data?.data?.lineChart?.bookings?.length === 0 ? <NoData data={true} /> : <Row className='mt-4'>
                                          <Col lg={8}>
                                                <Card className="graphs_card">

                                                      <div className='graph_header d-flex justify-content-between align-items-center'>

                                                            <Dropdown className='date_filter'>
                                                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                              <g clipPath="url(#clip0_181_9136)">
                                                                                    <path d="M15 2.25H14.25V1.5C14.25 1.0875 13.9125 0.75 13.5 0.75C13.0875 0.75 12.75 1.0875 12.75 1.5V2.25H5.25V1.5C5.25 1.0875 4.9125 0.75 4.5 0.75C4.0875 0.75 3.75 1.0875 3.75 1.5V2.25H3C2.175 2.25 1.5 2.925 1.5 3.75V15.75C1.5 16.575 2.175 17.25 3 17.25H15C15.825 17.25 16.5 16.575 16.5 15.75V3.75C16.5 2.925 15.825 2.25 15 2.25ZM14.25 15.75H3.75C3.3375 15.75 3 15.4125 3 15V6H15V15C15 15.4125 14.6625 15.75 14.25 15.75Z" fill="#A3AED0" />
                                                                              </g>
                                                                              <defs>
                                                                                    <clipPath id="clip0_181_9136">
                                                                                          <rect width="18" height="18" fill="white" />
                                                                                    </clipPath>
                                                                              </defs>
                                                                        </svg>   <span>{new Date().getFullYear()}</span>
                                                                  </Dropdown.Toggle>

                                                                  <Dropdown.Menu className='w-25'>
                                                                        {year.map((el, index) => {
                                                                              return (
                                                                                    <Dropdown.Item key={index}>{el}</Dropdown.Item>
                                                                              )
                                                                        })}
                                                                  </Dropdown.Menu>
                                                            </Dropdown>

                                                            <div className='stats_icon'>
                                                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <g clipPath="url(#clip0_181_9119)">
                                                                              <path d="M6.4 9.2H6.6C7.37 9.2 8 9.83 8 10.6V17.6C8 18.37 7.37 19 6.6 19H6.4C5.63 19 5 18.37 5 17.6V10.6C5 9.83 5.63 9.2 6.4 9.2ZM12 5C12.77 5 13.4 5.63 13.4 6.4V17.6C13.4 18.37 12.77 19 12 19C11.23 19 10.6 18.37 10.6 17.6V6.4C10.6 5.63 11.23 5 12 5ZM17.6 13C18.37 13 19 13.63 19 14.4V17.6C19 18.37 18.37 19 17.6 19C16.83 19 16.2 18.37 16.2 17.6V14.4C16.2 13.63 16.83 13 17.6 13Z" fill="#FF65C3" />
                                                                        </g>
                                                                        <defs>
                                                                              <clipPath id="clip0_181_9119">
                                                                                    <rect width="24" height="24" fill="white" />
                                                                              </clipPath>
                                                                        </defs>
                                                                  </svg>
                                                            </div>
                                                      </div>
                                                      <div className='spline_graph'>
                                                            {/* <div className='spline_content'>
                                                      <h3>$37.5K</h3>
                                                      <div className='total_Spend'>
                                                            <p className='mb-0'>Total Spent</p>
                                                      </div>
                                                      <p className='on_trach'>
                                                            <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                  <rect x="0.304199" width="16" height="16" rx="8" fill="#11A881" />
                                                                  <g clip-path="url(#clip0_181_9131)">
                                                                        <path d="M7.12489 9.74874L5.52072 8.14457C5.34197 7.96582 5.05781 7.96582 4.87906 8.14457C4.70031 8.32332 4.70031 8.60749 4.87906 8.78624L6.79947 10.7067C6.97822 10.8854 7.26697 10.8854 7.44572 10.7067L12.3041 5.85291C12.4828 5.67416 12.4828 5.38999 12.3041 5.21124C12.1253 5.03249 11.8411 5.03249 11.6624 5.21124L7.12489 9.74874Z" fill="white" />
                                                                  </g>
                                                                  <defs>
                                                                        <clipPath id="clip0_181_9131">
                                                                              <rect width="11" height="11" fill="white" transform="translate(2.99988 2.32373)" />
                                                                        </clipPath>
                                                                  </defs>
                                                            </svg>
                                                            On Track
                                                      </p>
                                                </div> */}
                                                            <div className='graph_wrap w-100 p-23'>
                                                                  <HighchartsReact
                                                                        highcharts={Highcharts}
                                                                        options={{
                                                                              chart: {
                                                                                    type: 'spline',
                                                                                    scrollablePlotArea: {
                                                                                          minWidth: '98%',
                                                                                          scrollPositionX: 1,
                                                                                    },

                                                                                    height: 320,
                                                                                    backgroundColor: 'transparent', // Set the background color to transparent
                                                                                    style: {
                                                                                          fontFamily: 'Arial', // Optionally, set a custom font family
                                                                                    },
                                                                              },
                                                                              // title: {
                                                                              //       text: 'Wind speed during a day',
                                                                              //       align: 'left',
                                                                              // },
                                                                              xAxis: {
                                                                                    categories: graph_data?.data?.lineChart?.bookings?.map((i) => i.month),
                                                                                    lineColor: 'transparent', // Set x-axis line color to transparent
                                                                                    tickColor: 'transparent', // Set x-axis tick color to transparent
                                                                              },
                                                                              yAxis: {
                                                                                    lineColor: 'transparent', // Set y-axis line color to transparent
                                                                                    tickColor: 'transparent', // Set y-axis tick color to transparent
                                                                              },
                                                                              // tooltip: {
                                                                              //       valueSuffix: ' m/s',
                                                                              // },
                                                                              plotOptions: {
                                                                                    plotBorderColor: null, // Set to null to hide plot background borders
                                                                                    plotBorderWidth: 0,    // Set to 0 to hide plot background borders
                                                                                    // spline: {
                                                                                    //       lineWidth: 6,
                                                                                    //       states: {
                                                                                    //             hover: {
                                                                                    //                   lineWidth: 7,
                                                                                    //             },
                                                                                    //       },
                                                                                    //       marker: {
                                                                                    //             enabled: false,
                                                                                    //       },
                                                                                    // },
                                                                              },
                                                                              series: [
                                                                                    {
                                                                                          name: 'Total Earnings',
                                                                                          color: '#FF65C3',
                                                                                          marker: {
                                                                                                symbol: 'round',
                                                                                          },
                                                                                          data: graph_data?.data?.lineChart?.bookings?.map((i) => i.totalEarnings),
                                                                                    },
                                                                                    {
                                                                                          name: 'Total Rides',
                                                                                          color: '#F2C94C',
                                                                                          data: graph_data?.data?.lineChart?.bookings?.map((i) => i.totalRides),
                                                                                    },
                                                                              ],
                                                                              navigation: {
                                                                                    menuItemStyle: {
                                                                                          fontSize: '10px',
                                                                                    },
                                                                              },
                                                                        }}
                                                                  />

                                                            </div>

                                                      </div>

                                                </Card>
                                          </Col>
                                          <Col lg={4}>
                                                <Card className="graphs_card">

                                                      {/* <div className='graph_header d-flex justify-content-between align-items-center'>
                                                            <h6 className='mb-0'>Your Pie Chart</h6>
                                                            <Dropdown className='date_filter'>
                                                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                                        <span>This month</span>
                                                                  </Dropdown.Toggle>
                                                                  <Dropdown.Menu>
                                                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                                  </Dropdown.Menu>
                                                            </Dropdown>

                                                      </div> */}
                                                      <HighchartsReact
                                                            highcharts={Highcharts}
                                                            options={{
                                                                  chart: {
                                                                        plotBackgroundColor: null,
                                                                        plotBorderWidth: null,
                                                                        plotShadow: false,
                                                                        type: 'pie',
                                                                        height: 390
                                                                  },
                                                                  title: {
                                                                        text: '',
                                                                        align: 'left'
                                                                  },
                                                                  tooltip: {
                                                                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                                                                  },
                                                                  accessibility: {
                                                                        point: {
                                                                              valueSuffix: '%'
                                                                        }
                                                                  },
                                                                  plotOptions: {
                                                                        pie: {
                                                                              allowPointSelect: true,
                                                                              cursor: 'pointer',
                                                                              dataLabels: {
                                                                                    enabled: false,
                                                                                    format: '<span style="font-size: 1.2em"><b>{point.name}</b></span><br>' +
                                                                                          '<span style="opacity: 0.6">{point.percentage:.1f} %</span>',
                                                                                    connectorColor: 'rgba(128,128,128,0.5)'
                                                                              },
                                                                              colors: ['#ff0000a8', '#F2C94C', '#ffcbd4', 'green'],
                                                                        }
                                                                  },
                                                                  series: [{
                                                                        name: 'Share',
                                                                        data: [
                                                                              { name: 'Cancelled Rides', y: graph_data?.data?.pieChart?.cancelledRides },
                                                                              { name: 'Not Accepted', y: graph_data?.data?.pieChart?.notAccepted },
                                                                              { name: 'Rejected Rides', y: graph_data?.data?.pieChart?.rejectedRides },
                                                                              { name: 'Completed Rides', y: graph_data?.data?.pieChart?.completedRides },
                                                                        ]
                                                                  }]
                                                            }}
                                                      />

                                                </Card>
                                          </Col>
                                    </Row>}
                                    <Row className='mt-4 d-none'>
                                          <Col lg={6}>
                                                <Card className="graphs_card">

                                                      <div className='graph_header d-flex justify-content-between align-items-center mb-3'>
                                                            <h6 className='mb-0'>Morning and Night Ride</h6>
                                                      </div>
                                                      <HighchartsReact
                                                            highcharts={Highcharts}
                                                            options={{
                                                                  chart: {
                                                                        type: 'areaspline',
                                                                        height: 225
                                                                  },
                                                                  title: {
                                                                        text: '',
                                                                        align: 'left'
                                                                  },
                                                                  subtitle: {
                                                                        text: '',
                                                                        align: 'left'
                                                                  },
                                                                  legend: {
                                                                        layout: 'vertical',
                                                                        align: 'left',
                                                                        verticalAlign: 'top',
                                                                        x: 120,
                                                                        y: 70,
                                                                        floating: true,
                                                                        borderWidth: 1,
                                                                        backgroundColor:
                                                                              Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF'
                                                                  },
                                                                  xAxis: {
                                                                        visible: false,
                                                                        plotBands: [{ // Highlight the two last years
                                                                              from: 2019,
                                                                              to: 2020,
                                                                              color: 'rgba(68, 170, 213, .2)'
                                                                        }]
                                                                  },
                                                                  yAxis: {
                                                                        visible: false,
                                                                        title: {
                                                                              text: 'Quantity'
                                                                        }
                                                                  },
                                                                  tooltip: {
                                                                        shared: true,
                                                                        headerFormat: '<b>Hunting season starting autumn {point.x}</b><br>'
                                                                  },
                                                                  credits: {
                                                                        enabled: false
                                                                  },
                                                                  plotOptions: {
                                                                        series: {
                                                                              pointStart: 2000,
                                                                              color: 'red' // Set the line color for all series
                                                                        },
                                                                        areaspline: {
                                                                              fillOpacity: 0.5
                                                                        }
                                                                  },
                                                                  series: [{
                                                                        name: 'Morning Rides',
                                                                        data: [38000, 30000, 35000, 38564, 36770, 44000, 34978, 35657],
                                                                        color: '#FF65C3' // Set the line color for this specific series
                                                                  }, {
                                                                        name: 'Night Rides',
                                                                        data: [22534, 17000, 24533, 20000, 25896, 27635, 29173, 17000],
                                                                        color: 'yellow' // Set the line color for this specific series
                                                                  }]
                                                            }}
                                                      />
                                                      <div className='pie_content'>
                                                            <ul>
                                                                  <li>
                                                                        <p><span></span>Morning Rides</p>
                                                                        <h3>$4,55</h3>
                                                                  </li>
                                                                  <li>
                                                                        <p><span className='yellow'></span>Night Rides</p>
                                                                        <h3>$4,545</h3>
                                                                  </li>
                                                            </ul>
                                                      </div>
                                                </Card>
                                          </Col>
                                          <Col lg={6}>
                                                <Card className="graphs_card">

                                                      <div className='graph_header d-flex justify-content-between align-items-center mb-3'>
                                                            <h6 className='mb-0'>Total Riders</h6>
                                                      </div>
                                                      <HighchartsReact
                                                            highcharts={Highcharts}
                                                            options={{
                                                                  data: {
                                                                        table: 'datatable'
                                                                  },
                                                                  chart: {
                                                                        type: 'column',
                                                                        height: 350
                                                                  },
                                                                  title: {
                                                                        text: ''
                                                                  },
                                                                  subtitle: {
                                                                        text:
                                                                              ''
                                                                  },
                                                                  xAxis: {
                                                                        type: 'category',
                                                                        categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
                                                                        // You can customize these labels based on your specific data
                                                                  },
                                                                  yAxis: {
                                                                        allowDecimals: false,
                                                                        title: {
                                                                              text: 'Amount'
                                                                        }
                                                                  },
                                                                  plotOptions: {
                                                                        column: {
                                                                              pointWidth: 12,
                                                                        }
                                                                  },
                                                                  series: [
                                                                        {
                                                                              name: 'Set 1',
                                                                              data: [10, 15, 20, 25, 30, 35, 40], // Sample data for Set 1,
                                                                              color: "#F2C94C"
                                                                        },
                                                                        {
                                                                              name: 'Set 2',
                                                                              data: [5, 10, 15, 20, 25, 30, 35], // Sample data for Set 2
                                                                              color: "#FF65C3"
                                                                        }
                                                                  ],
                                                                  // .
                                                            }}

                                                      />
                                                </Card>
                                          </Col>
                                    </Row>
                                    {/* Graphs */}
                                    <div className='mt-3'>
                                          <DataTable />
                                    </div>
                              </div>)
                  }
            </Wrapper>
      )
}

export default Dashboard