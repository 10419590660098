import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const get_all_terms_and_condition = createAsyncThunk("get_all_terms_and_condition", async ({ title }, thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/getAllAgreementContent?type=${title}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            throw new Error(errorMessage.message);
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const getAllTermsAndCondition = createSlice({
    name: "getAllTermsAndCondition",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_get_all_terms_and_condition: (state) => {
            state.data = []
            state.isError = false
            state.isSuccess = false
            state.loading = false
            state.error = null
            return state
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_all_terms_and_condition.pending, (state) => {
                state.loading = true
            })
            .addCase(get_all_terms_and_condition.fulfilled, (state, action) => {
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
                state.loading = false
            })
            .addCase(get_all_terms_and_condition.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const { clear_get_all_terms_and_condition } = getAllTermsAndCondition.actions;
export default getAllTermsAndCondition.reducer