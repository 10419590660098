import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const selected_driver_details = createAsyncThunk("selected_driver_details", async ({ id }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/getDriverDetails?id=${id}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }


})

export const get_selected_driver_detail = createSlice({
    name: "get_selected_driver_detail",
    initialState: {
        data: {},
        loading: false,
        isSuccess: false,
        isError: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(selected_driver_details.pending, (state) => {
                state.loading = true;
            })
            .addCase(selected_driver_details.fulfilled, (state, action) => {
                state.data = action.payload;
                state.isSuccess = true;
                state.loading = false;
            })
            .addCase(selected_driver_details.rejected, (state, action) => {
                state.error = action.payload;
                state.isError = true;
                state.loading = false;
            });
    },
});


export default get_selected_driver_detail.reducer