import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const get_location_pick_up = createAsyncThunk("get_location_pick_up", async ({ pik_up_latitude, pik_up_longitute }, thunkAPI) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    try {
        const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${pik_up_latitude}&lon=${pik_up_longitute}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const get_pik_up_location_detail = createSlice({
    name: "get_location_detail",
    initialState: {
        data: [],
        isError: false,
        isSuccess: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_pickup_location_State: (state) => {
            state.data = [];
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_location_pick_up.pending, (state) => {
                state.loading = true
            })
            .addCase(get_location_pick_up.fulfilled, (state, action) => {
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
                state.loading = false
            })
            .addCase(get_location_pick_up.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const { clear_pickup_location_State } = get_pik_up_location_detail.actions;
export default get_pik_up_location_detail.reducer