import React, { useEffect, useState } from 'react'
import Wrapper from '../Components/Wrapper/wrapper'
import DataTable from '../Components/Table/dataTable'
import { get_all_driver_request } from '../features/Driver_details/driver_detail_slice'
import { useDispatch, useSelector } from 'react-redux'
import Placeholder from 'react-bootstrap/Placeholder';
import { toast } from "react-hot-toast";
import CommonModal from '../Components/Modal/commonModal'
import RequestModal from '../Components/ModalBody/requestModal'
import { useNavigate } from 'react-router'
import NoData from '../Components/Nodata/noData'
import { clear_driver_details_State } from '../features/Driver_details/driver_detail_slice'
import ReactPaginate from 'react-paginate';

const DriverRequest = () => {
    const dispatch = useDispatch();
    const all_request = useSelector((state) => { return state.driver_requests })
    const navigate = useNavigate()
    const token = localStorage.getItem('token')
    const [loading, setLoading] = useState()
    const [drivers_request, setDrivers_request] = useState([])
    const [show, setShow] = useState(false); //show modal state
    const handleClose = () => setShow(false); // modal close function
    const handleShow = () => setShow(true); // modal show function
    const header = [{ name: "Sr No" }, { name: "Driver" }, { name: "Car Number" }, { name: "Phone" }, { name: "City" }, { name: "Details" }]
    const [driver_id, setDriver_id] = useState(null)
    const [no_record_found, setNo_record_found] = useState(false)
    const [page, setPage] = useState(1)
    const [coloum, setColoum] = useState("Driver")
    const [sort, setSort] = useState(1)

    useEffect(() => {
        return () => {
            // Your cleanup code or function goes here
            dispatch(clear_driver_details_State())
        };

    }, [])


    useEffect(() => {
        if (all_request?.data?.message == "Currently no driver found") {
            setNo_record_found(true)
        }
    }, [all_request])

    useEffect(() => {
        all_driver_request()
    }, [page, sort, coloum])

    const all_driver_request = () => {
        dispatch(get_all_driver_request({ token, page, sort, coloum }))
    }

    useEffect(() => {
        all_request?.loading ? setLoading(true) : setLoading(false)
        all_request?.data?.length !== 0 && setDrivers_request(all_request?.data?.data)
    }, [all_request]);

    useEffect(() => {
        if (all_request?.isError && all_request?.error?.message !== "") {
            toast.error(all_request?.error?.message)
        }
    }, [all_request?.isError])

    const handle_show_driver_details = (id) => {
        setDriver_id(id)
        handleShow()
    }

    const handlePageClick = (e) => {
        const NewPage = e.selected + 1;
        setPage(NewPage)

    }

    return (
        <>
            <Wrapper title="Driver requests">
                <DataTable loading={loading} header={header} setColoum={setColoum} setSort={setSort}>
                    {drivers_request?.map((all_driver_requests, index) => {
                        return (
                            <tr>
                                <td>{index + 1}
                                </td>
                                <td>{all_driver_requests?.personalDetails?.firstName + " " + all_driver_requests?.personalDetails?.lastName}
                                </td>
                                <td>{all_driver_requests?.vehicleDetails?.plateNumber}
                                </td>
                                <td>{all_driver_requests?.mobile}
                                </td>
                                <td>{all_driver_requests?.personalDetails?.city}
                                </td>
                                <td><p className='view_more' onClick={() => handle_show_driver_details(all_driver_requests?._id)}>View Details</p>
                                </td>
                            </tr>
                        )
                    })}

                </DataTable>

                <NoData data={no_record_found} />
                <CommonModal handleClose={handleClose} handleShow={handleShow} show={show} title="Car and Driver Details">
                    <RequestModal id={driver_id} all_driver_request={all_driver_request} handleClose={handleClose} />
                </CommonModal>
                {!no_record_found && <div className='custom_paginations'>
                    <p className='mb-0'></p>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={all_request?.data?.totalPages}
                        previousLabel="previous"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        activeClassName="active"
                    />
                </div>}
            </Wrapper>

        </>
    )
}

export default DriverRequest