import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from '../Components/Button/button'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-hot-toast";
import { forget_password, resend_otp, verify_otp, clearState } from '../features/ForgetPassword/forgetPasswordSlice';
import { useNavigate, useLocation } from 'react-router-dom';

const ForgotPassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation()
    let data = useSelector((state) => { return state.forgetPassword })
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [otp, setOtp] = useState("")
    const { forget_state } = location?.state ? location?.state : location;

    useEffect(() => {
        if (forget_state === undefined) {
            navigate('/')
        }
    }, [])

    const handle_submit = (event) => {
        event.preventDefault()
        if (!email) {
            toast.error("Please Enter Email")
        }
        else if (email && !email.includes("@")) {
            toast.error("Please Enter a Valid Email")
        }
        else {
            dispatch(forget_password({ email }))
        }
    }

    useEffect(() => {
        return () => {
            // Your cleanup code or function goes here
            dispatch(clearState())
        };

    }, [])

    useEffect(() => {
        if (data?.loading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
        if (data?.message?.message === "otp verified") {
            dispatch((clearState()))
            navigate("/reset-password", {
                state: {
                    email: email
                }
            })
        }
    }, [data])

    useEffect(() => {
        if (data?.isError && data?.error?.message) {
            toast.error(data?.error?.message)
        }
    }, [data?.isError && data?.error?.message])

    useEffect(() => {
        if (data?.message?.type === "success" && !data?.isSuccess) {
            setSuccess(true)
            toast.success(data?.message?.message)
        } else {
            setLoading(false)
        }
    }, [data?.message?.type === "success" && !data?.isSuccess])

    useEffect(() => {
        if (data?.isSuccess) {
            toast.success(data?.message?.message)
        }
    }, [data?.isSuccess])

    const handle_send_otp_again = () => {
        setOtp("")
        dispatch(resend_otp({ email }))
    }
    return (
        <Row className='m-0'>
            <Col lg={6} className='p-0'>
                <div className='Login_wrapper login_img_section'>
                    <img src="/images/ladygo_logo.png" alt="" />
                    <img src="/images/login.png" alt="" />
                </div>
            </Col>
            <Col lg={6} className='p-0'>
                <div className='Login_wrapper'>
                    <div className='login_form'>
                        <div className='text-center mb-3'>
                            <img src="/images/ladygo_logo.png" alt="" />
                        </div>
                        <div className='login_wrapper'>
                            {!success && <div>
                                <h2>Forgot Password</h2>
                                <div className='form-group mb-3'>
                                    <label htmlFor="">EMAIL ADDRESS</label>
                                    <input type="email" name="name" autoComplete="off" className='form-control' placeholder='Enter your email address' onChange={(e) => setEmail(e.target.value)} />
                                </div>

                                <div className='login_navs'>
                                    <Button onClick={(e) => { handle_submit(e) }} title="Send" className="theme_light w-100 mb-2" loading={loading} />
                                </div>
                            </div>}
                            {success && <div>
                                <div className='form-group mb-3'>
                                    <label htmlFor="">ENTER OTP</label>
                                    <input type="number" max={4} name="number" placeholder='0000' autoComplete="off" className='form-control' onChange={(e) => { setOtp(e.target.value) }} value={otp?.length > 4 ? otp.slice(0, 4) : null} />
                                </div>
                                <p style={{ cursor: "pointer" }} onClick={() => handle_send_otp_again()}>Resend Otp</p>

                                <div className='login_navs'>
                                    <Button onClick={() => { dispatch(verify_otp({ otp, email })); dispatch(clearState()) }} title="Send" className="theme_light w-100 mb-2" loading={loading} />
                                </div>
                            </div>}
                        </div>
                    </div>

                </div>
            </Col>
        </Row>
    )
}

export default ForgotPassword