import React from 'react'
import './reports.css'
import Wrapper from '../Components/Wrapper/wrapper'
import { useState } from 'react'
import CommonModal from '../Components/Modal/commonModal'
import ReportModal from '../Components/ModalBody/reportModal'
const Reports = () => {


const[viewReport, setViewReport] = useState(false);
const handleShowReport = () =>{
    setViewReport(true)
}
const handleCloseReport = () =>{
    setViewReport(false)
}
const viewIcon =    <svg onClick={handleShowReport} xmlns="http://www.w3.org/2000/svg" width="3" height="15" viewBox="0 0 3 15" fill="none">
<circle cx="1.5" cy="1.5" r="1.5" transform="matrix(4.37114e-08 1 1 -4.37114e-08 0 12)" fill="#737373" />
<circle cx="1.5" cy="1.5" r="1.5" transform="matrix(4.37114e-08 1 1 -4.37114e-08 0 6)" fill="#737373" />
<circle cx="1.5" cy="1.5" r="1.5" transform="matrix(4.37114e-08 1 1 -4.37114e-08 0 0)" fill="#737373" />
</svg>
    return (
        <Wrapper title="Reports">
            <div className="reports_wrapper">
                <h2 className='reports_heda'>Latest Customer’s Driver Reports</h2>
                <ul>
                    <li>
                        <div className='report_user_img'>
                            <img src="/images/user.png" alt="" />
                        </div>
                        <div className='report_user_info flex-grow-1'>
                            <h4>
                                Adison Rosser
                            </h4>
                            <p className='m-0'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form without relying on meaningful content.</p>
                        </div>
                     {viewIcon}
                    </li>
                    <li>
                        <div className='report_user_img'>
                            <img src="/images/user.png" alt="" />
                        </div>
                        <div className='report_user_info flex-grow-1'>
                            <h4>
                                Adison Rosser
                            </h4>
                            <p className='m-0'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form without relying on meaningful content.</p>
                        </div>
                     {viewIcon} 
                    </li>
                    <li>
                        <div className='report_user_img'>
                            <img src="/images/user.png" alt="" />
                        </div>
                        <div className='report_user_info flex-grow-1'>
                            <h4>
                                Adison Rosser
                            </h4>
                            <p className='m-0'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form without relying on meaningful content.</p>
                        </div>
                     {viewIcon}
                    </li>
                    <li>
                        <div className='report_user_img'>
                            <img src="/images/user.png" alt="" />
                        </div>
                        <div className='report_user_info flex-grow-1'>
                            <h4>
                                Adison Rosser
                            </h4>
                            <p className='m-0'>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form without relying on meaningful content.</p>
                        </div>
                     {viewIcon}
                    </li>
                </ul>
            </div>
            <CommonModal title="Report Details" handleClose={handleCloseReport} show={viewReport} >
                    <ReportModal/>
                </CommonModal>
        </Wrapper>
    )
}

export default Reports