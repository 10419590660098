import React, { useEffect } from 'react'
// import Wrapper from '../Wrapper/wrapper';
import DataTable from '../Table/dataTable';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from '../Button/button';
import CommonModal from '../Modal/commonModal';
import { useState } from 'react';
import AddVehicleModal from '../ModalBody/addVehicleModal';
import { useDispatch, useSelector } from 'react-redux';
import { get_all_vehicles_data } from '../../features/VehicleType/getAllVehicleSlice';
import EditVehicleModal from '../ModalBody/editVehicleModal';
import ConfirmModal from '../ModalBody/confirmModal';
import NoData from '../Nodata/noData';
import { toast } from "react-hot-toast";
import { delete_selected_vehicle } from '../../features/VehicleType/deleteSelectedVehicleSlice';
import { clear_delete_vehicle_State } from '../../features/VehicleType/deleteSelectedVehicleSlice';
import ReasonModal from '../ModalBody/resonModal';
import EditReasonModal from '../ModalBody/editReasonModal';
import { clear_get_all_vehicle_type_State } from '../../features/VehicleType/getAllVehicleSlice';
import { getCommission } from '../../features/AllDrivers/driverCommissionSlice';
import { updateAllDriverCommission } from '../../features/AllDrivers/updateAllDriverCommissionSlice';
import { clear_all_driver_commision_state } from '../../features/AllDrivers/updateAllDriverCommissionSlice';

const PricingTab = () => {
    const dispatch = useDispatch()
    const all_vehicles_data = useSelector((state) => { return state.get_all_vehicles })
    const [vehicle_data, setVehicle_data] = useState([])
    const [no_record_found, setNo_record_found] = useState(false)
    const [vehicle_id, setVehicle_id] = useState(null)
    const deleted_details = useSelector((state) => { return state.delete_vehicle_detail })
    const commission_status = useSelector((state) => { return state.all_driver_commission })
    const global_commission_state = useSelector((state) => { return state.all_commission_state })
    const [coloum, setColoum] = useState("Vehicle Type")
    const [sort, setSort] = useState(1)

    useEffect(() => {
        return () => {
            // Your cleanup code or function goes here
            dispatch(clear_get_all_vehicle_type_State())
        };

    }, [])

    const get_all_vehicle = () => {
        dispatch(get_all_vehicles_data())
        dispatch(getCommission())

    }

    useEffect(() => {
        get_all_vehicle()
    }, [])

    useEffect(() => {
        setVehicle_data(all_vehicles_data?.data)
        if (all_vehicles_data?.isSuccess && all_vehicles_data?.data.length === 0) {
            setNo_record_found(true)
        }
        if (all_vehicles_data?.isError) {
            toast.error("Something Went Wrong")

        }
    }, [all_vehicles_data])
    const headerheaderCancellation = [{ name: "Reason" }, { name: "Cancellation Type" }, { name: "Action" }]
    const header = [{ name: "Icon" }, { name: "Vehicle Type" }, { name: "Price per KM / day" }, { name: "Price per KM / night" }, { name: "Seats Capacity" }, { name: "Action" }]

    const [addVehicle, setAddVehicle] = useState(false)
    const handleCloseVehicle = () => {
        setAddVehicle(false)
    }
    const handleShowVehicle = () => {
        setAddVehicle(true)
    }
    const [editVehicle, setEditVehicle] = useState(false)
    const handleCloseEditVehicle = () => {
        setEditVehicle(false)
    }
    const handleShowEditVehicle = () => {
        setEditVehicle(true)
    }
    const [deleteVehicle, setDeleteVehicle] = useState(false)
    const handleCloseDeleteVehicle = () => {
        setDeleteVehicle(false)
    }
    const handleShowDeleteVehicle = () => {
        setDeleteVehicle(true)
    }
    const [cancellationReason, setCencellationReason] = useState(false)
    const handleCloseCancellationReason = () => {
        setCencellationReason(false)
    }
    const handleShowCancellationReason = () => {
        setCencellationReason(true)
    }
    const [cancellationReasonEdit, setCencellationReasonEdit] = useState(false)
    const handleCloseCancellationReasonEdit = () => {
        setCencellationReasonEdit(false)
    }
    const handleShowCancellationReasonEdit = () => {
        setCencellationReasonEdit(true)
    }
    const editIcon = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M5.75977 24.2399L7.25977 18.2999L16.1998 9.35986L20.6398 13.7999L11.6998 22.7399L5.75977 24.2399ZM8.33977 18.8999L7.43977 22.5599L11.0998 21.6599L18.9598 13.7999L16.1998 11.0399L8.33977 18.8999Z" fill="#FF65C3" />
        <path d="M10.6801 22.3802C10.3201 20.8802 9.12012 19.6802 7.62012 19.3202L7.92012 18.1802C9.84012 18.6602 11.3401 20.1602 11.8201 22.0802L10.6801 22.3802Z" fill="#FF65C3" />
        <path d="M17.5784 11.5724L18.4268 12.4208L10.6208 20.2328L9.77241 19.3868L17.5784 11.5724ZM6.59961 23.4002L8.33961 22.9802C8.15961 22.3202 7.67961 21.8402 7.01961 21.6602L6.59961 23.4002ZM20.9996 13.4402L16.5596 9.0002L18.3596 7.2002L18.6596 7.2602C20.8196 7.5602 22.4996 9.2402 22.7996 11.4002L22.8596 11.7002L20.9996 13.4402ZM18.2396 9.0002L20.9996 11.7602L21.5396 11.2202C21.2396 9.8402 20.1596 8.7602 18.7796 8.4602L18.2396 9.0002Z" fill="#FF65C3" />
    </svg>
    const deletIcon = <svg className='ms-4' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M7 21C6.45 21 5.979 20.804 5.587 20.412C5.195 20.02 4.99933 19.5493 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.804 20.021 18.412 20.413C18.02 20.805 17.5493 21.0007 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="#FF65C3" />
    </svg>

    const handle_delete = () => {
        handleShowDeleteVehicle()
    }

    const delete_record = () => {
        dispatch(delete_selected_vehicle({ vehicle_id }))
    }

    useEffect(() => {
        if (deleted_details?.isSuccess) {
            toast.success("Deleted Successfully !!")
            handleCloseDeleteVehicle()
            dispatch(clear_delete_vehicle_State())
            get_all_vehicle()
        }

        if (deleted_details?.isError) {
            toast.error("Something Went Wrong !!")
            handleCloseDeleteVehicle()
            dispatch(clear_delete_vehicle_State())
        }

    }, [deleted_details])

    useEffect(() => {
        if (global_commission_state?.isSuccess) {
            toast.success(global_commission_state?.message?.message)
            dispatch(clear_all_driver_commision_state())
            dispatch(getCommission())
        }

        if (global_commission_state?.isError) {
            toast.error(global_commission_state?.error?.message)
            dispatch(clear_all_driver_commision_state())
        }

    }, [global_commission_state])

    return (
        <>
            <div className='text-end mb-3 pt-3 d-flex justify-content-end gap-4 align-items-center'>
                <div className='align-items-center d-flex gap-2'>
                    <span className='d-block' style={{ color: "grey" }}>
                        Driver Commission :
                    </span>
                    <label class="switch">
                        <input type="checkbox" onChange={() =>
                            dispatch(updateAllDriverCommission({ commissionStatus: !commission_status?.message?.commissionStatus }))}
                            checked={commission_status?.message?.commissionStatus} />
                        <span class="slider_switch"></span>
                    </label>
                </div>
                <Button title="Add Vehicle" className="outline_btn" onClick={handleShowVehicle} />
            </div>
            <DataTable header={header} loading={all_vehicles_data?.loading} setColoum={setColoum} setSort={setSort} val={true}>
                {vehicle_data?.map((data, index) => {
                    return (
                        <tr key={index}>
                            <td><img height={40} width={50} src={data?.vehicleIcon} /></td>
                            <td>
                                {data?.type}
                            </td>
                            <td>
                                ${data?.dayPrice}
                            </td>
                            <td>
                                ${data?.nightPrice}
                            </td>
                            <td>
                                {data?.seatingCapacity}
                            </td>
                            <td>
                                <span onClick={() => { handleShowEditVehicle(); setVehicle_id(data?._id) }}> {editIcon}</span>
                                {/* <span onClick={() => { handle_delete(); setVehicle_id(data?._id) }}> {deletIcon}</span> */}
                            </td>
                        </tr>
                    )
                })}
            </DataTable>
            <NoData data={no_record_found} />
            <CommonModal title="Add Vehicle" handleClose={handleCloseVehicle} show={addVehicle}>
                <AddVehicleModal handleClose={handleCloseVehicle} get_all_vehicle={get_all_vehicle} />
            </CommonModal>
            <CommonModal show={editVehicle} handleClose={handleCloseEditVehicle} title="Edit vehicle" >
                <EditVehicleModal handleClose={handleCloseEditVehicle} get_all_vehicle={get_all_vehicle} vehicle_id={vehicle_id} />
            </CommonModal>
            <div className='confirmodal'>
                <CommonModal size="md" show={deleteVehicle} handleClose={handleCloseDeleteVehicle} className="confirmodal">
                    <ConfirmModal confirmMessage="Are you sure? you want to delete this" handleClose={handleCloseDeleteVehicle} delete_record={delete_record} loading={deleted_details?.loading} />
                </CommonModal>
            </div>
        </>
    )
}

export default PricingTab