import React, { useState, useEffect } from 'react'
import Wrapper from '../Components/Wrapper/wrapper'
import DataTable from '../Components/Table/dataTable'
import { useDispatch, useSelector } from 'react-redux'
import { get_all_delete_requests } from '../features/deleteRequests/get_all_delete_requests_slice'
import Moment from "react-moment";
import { RiDeleteBinLine } from "react-icons/ri";
import { delete_request } from '../features/deleteRequests/delete_request_slice'
import { clear_delete_requests } from '../features/deleteRequests/delete_request_slice'
import { toast } from "react-hot-toast";
import NoData from '../Components/Nodata/noData'


const DriverReq = () => {
    const dispatch = useDispatch()
    const [page, setPage] = useState(1)
    const [no_record_found, setNo_record_found] = useState(false)
    const header = [{ name: "Sr No" }, { name: "Name" }, { name: "Type" }, { name: "Joined Us On" }, { name: "Delete Req Created At" }, { name: "" }]
    const all_delete_req = useSelector((state) => { return state.get_all_delete_req })
    const [coloum, setColoum] = useState("Status")
    const [sort, setSort] = useState(1)

    const deleted_data = useSelector((state) => { return state.del_req })

    useEffect(() => {
        if (deleted_data?.isSuccess) {
            toast.success("Deleted Successfully !!")
            dispatch(get_all_delete_requests({ page }))
            dispatch(clear_delete_requests())
        }
    }, [deleted_data])

    useEffect(() => {
        dispatch(get_all_delete_requests({ page, sort, coloum }))
    }, [page, sort, coloum])
    useEffect(() => {
        if (all_delete_req?.data?.message === "No account delete request received yet") {
            setNo_record_found(true)
        }
        else {
            setNo_record_found(false)
        }
    }, [all_delete_req])

    return (
        <Wrapper title="Delete requests">
            <DataTable loading={all_delete_req?.loading} header={header} setColoum={setColoum} setSort={setSort}>
                {all_delete_req?.data?.accountDeleteRequests?.map((all_delete_requests, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{all_delete_requests?.firstName + " " + all_delete_requests?.lastName}</td>
                            <td>{all_delete_requests?.type}</td>
                            <td><Moment format='  Do MMMM YYYY h:mm:ss a'>{all_delete_requests?.createdAt}</Moment></td>
                            <td><Moment format='  Do MMMM YYYY h:mm:ss a'>{all_delete_requests?.deletedAt}</Moment></td>
                            <td>
                                <p className='view_more' title={"Delete " + all_delete_requests?.firstName + " " + all_delete_requests?.lastName} onClick={() => dispatch(delete_request({ id: all_delete_requests?._id, type: all_delete_requests?.type }))}><RiDeleteBinLine size={24} /></p>
                            </td>
                        </tr>
                    );
                })}
            </DataTable>
            <NoData data={no_record_found} />
        </Wrapper>
    )
}

export default DriverReq