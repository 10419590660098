import React, { useEffect } from 'react'
import Wrapper from '../Components/Wrapper/wrapper'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CommonModal from '../Components/Modal/commonModal';
import { useState } from 'react';
import './setting.css'
import ConfirmModal from '../Components/ModalBody/confirmModal';
import PricingTab from '../Components/Tabs/pricingTab';
import CancellationTab from '../Components/Tabs/cancellationTab';
const Settings = () => {

    const [deleteVehicle, setDeleteVehicle] = useState(false)
    const handleCloseDeleteVehicle = () => {
        setDeleteVehicle(false)
    }
    return (
        <Wrapper title="Settings">
            <Tabs
                defaultActiveKey="pricing"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="pricing" title="Pricing">
                    <PricingTab />
                </Tab>
                <Tab eventKey="cancellation" title="Cancellation">
                    <CancellationTab />
                </Tab>
            </Tabs>
        </Wrapper>
    )
}

export default Settings