import Swal from 'sweetalert2';

export const showDeleteModal = (id, type, onSuccess) => {
  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    input: 'text',
    inputPlaceholder: 'Enter the reason for deletion',
    inputAttributes: {
      'aria-label': 'Enter the reason for deletion'
    },
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
    preConfirm: (reason) => {
      if (!reason) {
        Swal.showValidationMessage('You need to write the reason for deletion');
        return false;
      } else {
        return reason;
      }
    }
  }).then((result) => {
    if (result.isConfirmed) {
      const reason = result.value;
      let token = localStorage.getItem("token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + token);
      myHeaders.append("Content-Type", "application/json"); 

      const raw = JSON.stringify({
        accountDeleteReason: reason 
      });

      const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/deleteDriverOrPassenger?id=${id}&type=${type}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log('result -------', result);
          if (onSuccess) 
             onSuccess();
        })
        .catch((error) => console.error('Error:', error));
    }
  });
};
