import logo from './logo.svg';
import './App.css';
// import Wrapper from './Components/Wrapper/wrapper';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Login from './Auth/login';
import Dashboard from './Pages/dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Bookings from './Pages/bookings';
import Tracking from './Pages/tracking';
import Drivers from './Pages/drivers';
import { VerifyAuth } from './middleware/verifyAuth';
import ForgotPassword from './Auth/forgot';
import ResetPassword from './Auth/resetPassword';
import DriverRequest from './Pages/driverRequest';
import DriverDetails from './Pages/driverDetails';
import Settings from './Pages/settings';
import Reports from './Pages/reports';
import Riders from './Pages/riders';
import BookingDetails from './Pages/bookingDetails';
import Wallet from './Pages/wallet';
import WalletHistory from './Pages/walletHistory';
import Help from './Pages/help';
import DriverReq from './Pages/DriverReq';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes >
          <Route path="/dashboard" element={<VerifyAuth><Dashboard /></VerifyAuth>} />
          <Route path="/" element={<Login />} />
          <Route path="/booking" element={<VerifyAuth><Bookings /></VerifyAuth>} />
          <Route path="/booking/BookingDetails" element={<VerifyAuth><BookingDetails /></VerifyAuth>} />
          <Route path="/tracking" element={<VerifyAuth><Tracking /></VerifyAuth>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/drivers" element={<Drivers />} />
          <Route path="/driver_request" element={<DriverRequest />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/drivers/driver-details" element={<DriverDetails />} />
          <Route path="/rider" element={<Riders />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/wallet/walletHistory" element={<WalletHistory />} />
          <Route path="/help" element={<Help />} />
          <Route path="/delete_requests" element={<DriverReq />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
