import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const forget_password = createAsyncThunk("forget", async ({ email }, thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "email": email
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/forgetPassword`, requestOptions)

        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }

        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const resend_otp = createAsyncThunk("resend", async ({ email }, thunkAPI) => {
    try {
        var requestOptions = {
            method: 'POST',
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/resend-otp?email=${email}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }

        }

        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const verify_otp = createAsyncThunk("verify", async ({ otp, email }, thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "otp": otp
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/forgetPsd-verifyOtp?email=${email}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const forgetPassword = createSlice({
    name: "forgetPassword",
    initialState: {
        message: "",
        loading: false,
        error: null,
        isSuccess: false,
        isError: false
    },
    reducers: {
        clearState: (state) => {
            state.message = "";
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: {
        [forget_password.pending]: (state) => {
            state.loading = true;
        },
        [forget_password.fulfilled]: (state, action) => {
            state.loading = false;
            state.message = action.payload;
        },
        [forget_password.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isError = true;
            state.message = ""
        },
        [resend_otp.pending]: (state) => {
            state.loading = true;
        },
        [resend_otp.fulfilled]: (state, action) => {
            state.isSuccess = true
            state.loading = false;
            state.message = action.payload;
        },
        [resend_otp.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isError = true;
            state.message = ""
        },
        [verify_otp.pending]: (state) => {
            state.loading = true;
        },
        [verify_otp.fulfilled]: (state, action) => {
            state.isSuccess = true
            state.loading = false;
            state.message = action.payload;
        },
        [verify_otp.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isError = true
            state.message = ""
        }
    }
})
export const { clearState } = forgetPassword.actions;
export default forgetPassword.reducer