import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from '../Button/button'
import { useDispatch, useSelector } from 'react-redux'
import { get_selected_vehicle_data } from '../../features/VehicleType/getSingleVehicleDataSlice'
import { clear_get_vehicle_State } from '../../features/VehicleType/getSingleVehicleDataSlice'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { update_selected_vehicle_type } from '../../features/VehicleType/updateVehicleTypeSlice'
import { toast } from "react-hot-toast";
import { clear_update_vehicle_State } from '../../features/VehicleType/updateVehicleTypeSlice'
import Loader from '../Loader'

const EditVehicleModal = ({ handleClose, get_all_vehicle, vehicle_id }) => {
    const dispatch = useDispatch()
    const selected_vehicle_data = useSelector((state) => { return state.single_vehicle_details })
    const [vehicle_type, setVehicle_type] = useState("")
    const [day_price, setDay_price] = useState(null)
    const [night_price, setNight_price] = useState(null)
    const [seat_capacity, setSeat_capacity] = useState(null)
    const update_vehicle_data = useSelector((state) => { return state.update_vehicle_type })
    const [vehicleIcon, setVehicleIcon] = useState("")

    useEffect(() => {
        return () => {
            // Your cleanup code or function goes here
            dispatch(clear_update_vehicle_State())
        };

    }, [])

    useEffect(() => {
        dispatch(get_selected_vehicle_data({ vehicle_id }))
    }, [])

    useEffect(() => {
        setVehicle_type(selected_vehicle_data?.data?.type)
        setDay_price(selected_vehicle_data?.data?.dayPrice)
        setNight_price(selected_vehicle_data?.data?.nightPrice)
        setSeat_capacity(selected_vehicle_data?.data?.seatingCapacity)
        if (update_vehicle_data?.isSuccess) {
            toast.success("Update Successfully")
            get_all_vehicle()
            dispatch(clear_update_vehicle_State())
            handleClose()
        }
        if (update_vehicle_data?.isError) {
            toast.error(update_vehicle_data?.error?.message)
            dispatch(clear_update_vehicle_State())
        }
    }, [selected_vehicle_data, update_vehicle_data])

    const handle_update = () => {
        if (vehicle_type === "" || day_price === null || night_price === null || seat_capacity === null) {
            toast.error("fields can't be empty");
        }
        else {
            dispatch(update_selected_vehicle_type({ vehicle_id, vehicle_type, day_price, night_price, seat_capacity, vehicleIcon }))
        }
    }

    return (
        <Row className='m-0 form_wrapper'>
            <Col lg={6}>
                <div className='form-group mb-3'>
                    <label htmlFor="">Vehicle Type</label>
                
                        <select className="form-select" value={vehicle_type} onChange={(e) => setVehicle_type(e.target.value)}>
                            <>
                                <option>Select</option>
                                <option value="Just-Go">Just-Go</option>
                                <option value="Electric Car">Electric-Car</option>
                                <option value="Limousine">Limousine</option>
                                <option value="Luxury">Luxury</option>
                                <option value="Taxi-4 Seat">Taxi-4 Seat</option>
                                <option value="Taxi-7 seat">Taxi-7 Seat</option>
                            </>
                        </select>
                    
                </div>
            </Col>

            <Col lg={6}>
                <div className='form-group mb-3'>
                    <label htmlFor="">Price per KM / day</label>
                   <input type="number" placeholder='$4' className='form-control' value={day_price} onChange={(e) => setDay_price(e.target.value)} />
                </div>
            </Col>
            <Col lg={6}>
                <div className='form-group mb-3'>
                    <label htmlFor="">Price per KM / night</label>
                 <input type="number" placeholder='$10' className='form-control' value={night_price} onChange={(e) => setNight_price(e.target.value)} />
                </div>
            </Col>
            <Col lg={6}>
                <div className='form-group mb-3'>
                    <label htmlFor="">Seating capacity</label>
                 <input type="text" placeholder='4' className='form-control' value={seat_capacity} onChange={(e) => setSeat_capacity(e.target.value)} />
                </div>
            </Col>
            <Col lg={12}>
                <div className='form-group mb-3'>
                    <label htmlFor="">Vehicle Icon</label>
                    <div className='car_image_view'>
                        {selected_vehicle_data?.data?.vehicleIcon ? <img src={selected_vehicle_data?.data?.vehicleIcon} alt='car_image' /> : <img src="/images/car.png" />}
                        <input type="file" placeholder='4' className='form-control' onChange={(e) => setVehicleIcon(e.target.files[0])} />
                    </div>
                </div>
            </Col>
            <Col lg={12} className='text-center mt-2'>
                <Button title="Cancel" className="outline_btn me-4" onClick={handleClose} />
                <Button title="Update" className="theme_light" onClick={() => handle_update()} loading={update_vehicle_data?.loading} />
            </Col>
        </Row>

    )
}

export default EditVehicleModal