import React, { useEffect, useState } from 'react'
import Wrapper from '../Components/Wrapper/wrapper'
import './drivers.css'
import Button from '../Components/Button/button';
import DataTable from '../Components/Table/dataTable';
import { useDispatch, useSelector } from 'react-redux';
import { get_all_drivers } from '../features/AllDrivers/all_driver_slice';
import Rating from '../Components/Rating/rating';
import { FaRegEye, FaSearch } from "react-icons/fa";
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router';
import NoData from '../Components/Nodata/noData';
import { clear_all_driver_details_State } from '../features/AllDrivers/all_driver_slice';
import { RiDeleteBinLine } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import { showDeleteModal } from '../Components/DeleteWarning/deleteWarning'
import EditModal from '../Components/EditModal/editModal'


const starSvg = <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
  <path d="M9.78572 3.1902L10.9261 5.48725C11.0823 5.80254 11.4957 6.1138 11.8445 6.16887L13.9198 6.52378C15.2487 6.74812 15.5512 7.71171 14.5978 8.66465L12.9739 10.2786C12.7027 10.5492 12.5446 11.0812 12.6321 11.455L13.0871 13.4602C13.4447 15.0432 12.604 15.6555 11.2111 14.824L9.26544 13.6621C8.91111 13.4498 8.3332 13.4528 7.98236 13.658L6.03033 14.802C4.633 15.6197 3.79426 15.0034 4.16297 13.4241L4.63406 11.4257C4.72203 11.0521 4.5745 10.5188 4.30398 10.2477L2.68086 8.62084C1.73392 7.6643 2.04268 6.70327 3.37071 6.48583L5.44427 6.14782C5.79435 6.08788 6.21027 5.78787 6.36569 5.47074L7.52209 3.17715C8.15338 1.94177 9.17018 1.94707 9.78572 3.1902Z" fill="#F2C94C" />
</svg>;
const Drivers = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const all_drivers_data = useSelector((state) => { return state.all_drivers })
  let loading = all_drivers_data?.loading
  const [page, setPage] = useState(1)
  const header = [{ name: "Driver ID" }, { name: "Driver" }, { name: "Mobile Number" }, { name: "Ratings" }, { name: "Total Trips" }, { name: "Status" }, { name: "Total Earning" }, { name: "" },{name:''},{name:''}]
  const [drivers_data, setDrivers_data] = useState([])
  const [no_record_found, setNo_record_found] = useState(false)
  const [search, setSearch] = useState("")
  const [sort, setSort] = useState(1)
  const [coloum, setColoum] = useState("Driver ID")
  const [rating, setRating] = useState("")
  const [handleChnage, setHandleChange] = useState(0)
  const [showModal, setShowModal] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [modalFields, setModalFields] = useState([]);
  const [apiEndpoint, setApiEndpoint] = useState('');

  useEffect(() => {
    return () => {
      // Your cleanup code or function goes here
      dispatch(clear_all_driver_details_State())
    };

  }, [])

  useEffect(() => {
    setDrivers_data(all_drivers_data?.data?.data)
  }, [all_drivers_data])

  useEffect(() => {
    dispatch(get_all_drivers({ coloum, page, search, sort, rating }))
  }, [coloum, page, search, sort, rating,handleChnage])


  const handlePageClick = (e) => {
    const NewPage = e.selected + 1;
    setPage(NewPage)
  }

  useEffect(() => {
    if (all_drivers_data?.data?.message === "Currently no driver found") {
      setNo_record_found(true)
    }
    else {
      setNo_record_found(false)
    }
  }, [all_drivers_data])


  const handleDelete = (id, type) => {
    showDeleteModal(id, type, () => {
      setHandleChange(prev =>prev+1 ); 
    })
}




const handleEdit = (data) => {
  console.log("driver details ----------->>>.", data);
  setCurrentData(data);
  setModalFields([
    { label: 'First Name', name: 'personalDetails.firstName' },
    { label: 'Last Name', name: 'personalDetails.lastName' }
  ]);
  setApiEndpoint(`${process.env.REACT_APP_BACKEND_URL}api/v1/updateDriverDetails?driverId=${data._id}`);
  setShowModal(true);
};






  return (
    <Wrapper title="Drivers">
      <div className="filters short_length">
        <ul>
          <li className="search">
            <input type="text" name="" id="" placeholder="Search...." value={search} onChange={(e) => setSearch(e.target.value)} />
            <FaSearch />
          </li>
          {/* <li className='flex-grow-1'>
            <select onChange={(e) => setRating(e.target.value)} value={rating}>
              <option>Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </li> */}
          <li className="text-end ms-auto"><button className="lgo_cmn_btn outline_btn" onClick={() => { setSearch(""); setSort(1); setPage(page); setColoum("Driver ID"); setRating("") }}><span> Reset Filter</span></button></li>
        </ul>
      </div>
      <DataTable header={header} loading={loading} setColoum={setColoum} setSort={setSort}>
        {drivers_data?.map((all_driver_data, index) => {
          console.log(all_driver_data, "this is the all dribeer daat")
          return (
            <tr key={index}>
              <td>{all_driver_data?._id}
              </td>
              <td>{all_driver_data?.personalDetails?.firstName + " " + all_driver_data?.personalDetails?.lastName}
              </td>
              <td>{all_driver_data?.mobile ? "+" + all_driver_data?.mobile : "Not Available"}
              </td>
              <td>
                <Rating value={all_driver_data?.averageRating ? all_driver_data?.averageRating : 0} />
              </td>
              <td>{all_driver_data?.totalCompletedRides ? all_driver_data?.totalCompletedRides : 0}
              </td>
              <td style={all_driver_data?.onlineStatus === "online" ? { color: "green" } : { color: "red" }}> {all_driver_data?.onlineStatus}
              </td>
              <td>  {all_driver_data?.totalEarnings}
              </td>
              <td title="View Details" style={{ cursor: "pointer" }} onClick={() => navigate("/drivers/driver-details", { state: { id: all_driver_data?._id } })}><FaRegEye /></td>
              <td onClick={()=>handleDelete(all_driver_data?.userID,'driver')}> <RiDeleteBinLine/></td>
              <td onClick={() => handleEdit(all_driver_data)}><GrEdit /></td>
            </tr>
          )
        })}
      </DataTable>
      <NoData data={no_record_found} />
      {!no_record_found && <div className='custom_paginations'>
        <p className='mb-0'></p>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={all_drivers_data?.data?.totalPages}
          previousLabel="previous"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>}
      <EditModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        data={currentData}
        fields={modalFields}
        onSave={(updatedData) => setCurrentData(updatedData)}
        apiEndpoint={apiEndpoint}
        handleChange={setHandleChange}
      />
    </Wrapper>
  )
}

export default Drivers