import React, { useState, useEffect } from 'react'
import Button from '../Button/button'
import { useDispatch, useSelector } from 'react-redux'
import { creditAndDebit } from '../../features/wallet/credit_debitSlice'
import { toast } from 'react-hot-toast'
import Loader from '../Loader'
import { clear_credit_debit_state } from '../../features/wallet/credit_debitSlice'
import { view_selected_driver_wallet_detail } from '../../features/wallet/get_selected_driver_passenger_detail_slice'
const DebitModal = ({ handleClose, type, user_id, paymentType, setLoad, totalAmount, setShowDebit }) => {
    const dispatch = useDispatch();
    const credit_debit_data = useSelector((state) => { return state.credit_debit_payment })
    const [price, setPrice] = useState(null);
    const [reason, setReason] = useState("");


    const handleCredit = () => {
        if (price !== null && reason !== "") {
            if (price > totalAmount) {
                toast.error(`You cannot add price more than ${totalAmount}`)
            }
            else {
                dispatch(creditAndDebit({ type, user_id, paymentType, price, reason }))
            }
        }
        else {
            toast.error("All Fields Are Mandatory !!!")
        }
    }

    useEffect(() => {
        if (credit_debit_data?.isSuccess) {
            dispatch(view_selected_driver_wallet_detail({ type, user_id }))
            setLoad(true)
            toast.success("Success !!")
            handleClose();
            setShowDebit(false)
            setPrice(null)
            setReason("")
            dispatch(clear_credit_debit_state());
        }
    }, [credit_debit_data])
    return (
        <>
            {credit_debit_data?.loading ? <Loader /> : <div className='form_wrapper '>
                <div class="form-group mb-2">
                    <label for="" className='mt-0'>Price</label>
                    <input type="number" placeholder="$10" class="form-control" value={price} onChange={(e) => setPrice(e.target.value)} />
                </div>
                <div class="form-group mb-3">
                    <label for="">Reason</label>
                    <textarea name="" className='form-control' id="" cols="30" rows="7" onChange={(e) => setReason(e.target.value)} value={reason} />
                </div>
                <Button title="Send" className="w-100 theme_light" onClick={() => handleCredit()} />
            </div>}
        </>
    )
}

export default DebitModal