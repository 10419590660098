import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const password_reset = createAsyncThunk("resetPassword", async ({ email, new_password, confirm_password }, thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "password": new_password,
            "confirmPassword": confirm_password
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/resetPassword?email=${email}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const reset_password = createSlice({
    name: "reset_password",
    initialState: {
        message: "",
        error: null,
        isError: false,
        isSuccess: false,
        loading: false
    },
    reducers: {
        clear_reset_password_state: (state) => {
            state.message = "";
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }
    },
    extraReducers: {
        [password_reset.pending]: (state) => {
            state.loading = true
        },
        [password_reset.fulfilled]: (state, action) => {
            state.loading = false;
            state.message = action.payload;
            state.error = false;
            state.isError = false;
            state.isSuccess = true
        },
        [password_reset.rejected]: (state, action) => {
            state.error = action.payload;
            state.isError = true
        }
    }
})

export const { clear_reset_password_state } = reset_password.actions;
export default reset_password.reducer;