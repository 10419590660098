import React, { useState } from 'react';
import Wrapper from '../Components/Wrapper/wrapper';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TermsAndConditionsTab from '../Components/Tabs/termsAndConditionsTab';
import FaqsTab from '../Components/Tabs/faqsTab';
import PrivacyTab from '../Components/Tabs/privacyTab';

const Help = () => {
  const [activeTab, setActiveTab] = useState('terms');

  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  return (
    <Wrapper title="Help">
      <Tabs
        activeKey={activeTab}
        onSelect={handleTabSelect}
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="terms" title="Terms and Conditions">
          {activeTab === 'terms' && <TermsAndConditionsTab term={true} />}
        </Tab>
        <Tab eventKey="privacy" title="Privacy Policy">
          {activeTab === 'privacy' && <PrivacyTab privacy={true} />}
        </Tab>
        <Tab eventKey="faq" title="FAQ's">
          {activeTab === 'faq' && <FaqsTab />}
        </Tab>
      </Tabs>
    </Wrapper>
  );
};

export default Help;
