import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const get_selected_vehicle_data = createAsyncThunk("get_selected_vehicle_data", async ({ vehicle_id }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/vehicleTypes/${vehicle_id}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const get_single_vehicle_detail = createSlice({
    name: "get_single_vehicle_detail",
    initialState: {
        data: {},
        isSuccess: false,
        isError: false,
        loading: false,
        error: false
    },
    reducers: {
        clear_get_vehicle_State: (state) => {
            state.data = {};
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(get_selected_vehicle_data.pending, (state) => {
            state.loading = true
        })
            .addCase(get_selected_vehicle_data.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
            })
            .addCase(get_selected_vehicle_data.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
                state.isError = true
                state.isSuccess = true
            })
    }
})
export const { clear_get_vehicle_State } = get_single_vehicle_detail.actions;
export default get_single_vehicle_detail.reducer