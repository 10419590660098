import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Loader from '../Loader';
const CommonModal = ({ children, show, handleClose, title, className, size, handleCloseEdit }) => {
  return (
    <Modal centered size={size ? `${size}` : "lg"} show={show} onHide={handleClose ? handleClose : handleCloseEdit} className={className ? `${className}` : ""}>
      {/* <Loader/> */}
      <Modal.Header closeButton className='modal_header'>
        <Modal.Title>{title} </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal_body'>
        {children}
      </Modal.Body>
    </Modal>
  )
}

export default CommonModal