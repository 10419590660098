import React, { useEffect, useState } from 'react'
import Wrapper from '../Components/Wrapper/wrapper'
import Button from '../Components/Button/button'
import { Col, Row } from 'react-bootstrap'
import DataTable from '../Components/Table/dataTable'
import CommonModal from '../Components/Modal/commonModal'
import CreditModal from '../Components/ModalBody/creditModal'
import { useDispatch, useSelector } from 'react-redux'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { useLocation } from 'react-router-dom'
import { view_selected_driver_wallet_detail } from '../features/wallet/get_selected_driver_passenger_detail_slice'
import Moment from "react-moment";
import DebitModal from '../Components/ModalBody/debitModal'
import NoData from '../Components/Nodata/noData'
import Loader from '../Components/Loader'
const WalletHistory = () => {
    const location = useLocation()
    const { user_id, type } = location?.state ? location?.state : location;
    const dispatch = useDispatch()
    const header = [{ name: "Status" }, { name: "Date" }, { name: "Time" }, { name: "Amount" }]
    const [show, setShow] = useState(false);
    const [showDebit, setShowDebit] = useState(false);
    const [load, setLoad] = useState(false)
    const [no_record_found, setNo_record_found] = useState(false)
    const wallet_details = useSelector((state) => { return state.selected_user_wallet_history })
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseDebit = () => setShowDebit(false);
    const handleShowDebit = () => setShowDebit(true);
    const [coloum, setColoum] = useState("Status")
    const [sort, setSort] = useState(1)
    useEffect(() => {
        dispatch(view_selected_driver_wallet_detail({ type, user_id, sort, coloum }))
        setShowDebit(false)
    }, [sort, coloum])

    useEffect(() => {
        if (wallet_details?.data?.wallet_transactions?.length === 0) {
            setNo_record_found(true)
        } else {
            setNo_record_found(false)
        }
    }, [wallet_details])

    return (
        <Wrapper title={type === "driver" ? "Driver Wallet History" : "Rider Wallet History"}>
            {wallet_details?.loading && !load ? <Loader /> : <>
                <div className='wallet_header'>
                    <div className='d-flex gap-2 align-items-center mb-3'>
                        <h5 className='flex-grow-1 mb-0 cmn_heading'>{type === "driver" ? "Driver Details" : "Rider Details"}</h5>
                        <div title="Credit Amount To Wallet"><Button onClick={handleShow} className="theme_light" title="Credit" /></div> <div title="Debit Amount From Wallet"><Button onClick={handleShowDebit} title="Debit" className="outline_btn" /></div>
                    </div>
                    <div className='wallet_info driver_info_list'>
                        <Row className='m-0 align-items-end'>
                            <Col lg={6} className='ps-0'>
                                <ul className='mb-0'>
                                    <PhotoProvider maskOpacity={0.7} speed={() => 800}
                                        easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
                                    >
                                        <li title="View Profile pic" style={{ cursor: "pointer" }}>
                                            <PhotoView src={wallet_details?.data?.userDetails?.profile}>
                                                <img src={wallet_details?.data?.userDetails?.profile} alt="" />
                                            </PhotoView>
                                        </li>
                                    </PhotoProvider>
                                    <li>
                                        <p class="mb-1">Name</p>
                                        <strong>{wallet_details?.data?.userDetails?.firstName + " " + wallet_details?.data?.userDetails?.lastName}</strong>
                                        <p class="mb-1 mt-4 pt-3">E-mail</p>
                                        <strong>{wallet_details?.data?.userDetails?.email}</strong></li>
                                    <li>
                                        <p className='mb-0'>Phone Number</p>
                                        <strong>{wallet_details?.data?.userDetails?.mobile}</strong></li>
                                </ul>
                            </Col>
                            <Col lg={6}>
                                <div className='wallet_total test-end'>
                                    <div className='d-inline text-center'>
                                        <h2 className=''>
                                            {wallet_details?.data?.userDetails?.currency} {wallet_details?.data?.userDetails?.remainingBalance}
                                        </h2>
                                        <p className='mb-0'>Total Wallet Amount</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <h5 className='flex-grow-1 mb-4 mt-4 cmn_heading'>Wallet transaction history</h5>
                <DataTable header={header} setColoum={setColoum} setSort={setSort}>
                    {
                        wallet_details?.data?.wallet_transactions?.map((data, index) => {
                            return (
                                <tr>
                                    <td style={{ color: data?.payment_type === "CR" ? "green" : "red" }}>{data?.payment_type === "CR" ? "Credit" : "Debit"}</td>
                                    <td><Moment format=' Do MMMM YYYY'>
                                        {data?.createdAt}
                                    </Moment></td>
                                    <td><Moment format=' h:mm:ss a'>
                                        {data?.createdAt}
                                    </Moment></td>
                                    <td style={{ color: data?.payment_type === "CR" ? "green" : "red" }}>{data?.payment_type === "CR" ? "+" + data?.amount : "-" + data?.amount}</td>
                                </tr>
                            )
                        })
                    }
                </DataTable>
            </>}
            <NoData data={no_record_found} />
            <CommonModal show={show} handleClose={handleClose} title="Credit" size="md">
                <CreditModal handleClose={handleClose} type={type} user_id={user_id} paymentType="CR" setLoad={setLoad} setShowDebit={setShowDebit} />
            </CommonModal>
            {wallet_details?.data?.userDetails?.remainingBalance > 0 && (
                <CommonModal show={showDebit} handleClose={handleCloseDebit} title="Debit" size="md">
                    {wallet_details?.data?.userDetails?.remainingBalance > 0 && <DebitModal
                        handleClose={handleCloseDebit}
                        type={type}
                        user_id={user_id}
                        paymentType="DR"
                        setLoad={setLoad}
                        totalAmount={wallet_details?.data?.userDetails?.remainingBalance}
                        setShowDebit={setShowDebit}
                    />}
                </CommonModal>
            )}

        </Wrapper>
    )
}

export default WalletHistory