import React from 'react'

const ReportModal = () => {
    return (
        <div>
            <h3 className='detail_title'>Driver Details</h3>
            <div className='driver_request_details'>
                <div className='driver_image'>
                    <img src="/images/driver.png" width={150} alt="" />
                </div>
                <div className='w-100'>
                    <ul>
                        <li>
                            <h3><strong>Rider Name</strong></h3>
                            <h3>Deepak Singh</h3>
                        </li>
                        <li>
                            <h3><strong>Mobile Number</strong></h3>
                            <h3>9856895689</h3>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h3><strong>Email</strong></h3>
                            <h3>examplat@gmail.com</h3>
                        </li>
                        <li>
                            <h3><strong>Driver license Number</strong></h3>
                            <h3>Dl17 566575755</h3>
                        </li>
                        {/* <li>
                              <h3><strong>Driver Licsence Number</strong></h3>
                              <h3>{driver_data?.data?.data?.email ? driver_data?.data?.data?.email : "Not Available"}</h3>
                          </li> */}
                    </ul>
                    <ul>
                        <li>
                            <h3><strong>Report Message</strong></h3>
                            <h3>
                                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.

                            </h3>
                        </li>
                    </ul>

                </div>
            </div>
            <hr />
            <h3 className='detail_title'>Driver and Car Details</h3>
            <div className='driver_request_details'>
                <div className='driver_image'>
                    <img src="/images/driver.png" width={150} alt="" />
                </div>
                <div className='w-100'>
                    <ul>
                        <li>
                            <h3><strong>Driver Name</strong></h3>
                            <h3>Deepak Singh</h3>
                        </li>
                       
                        <li>
                            <h3><strong>License Plate Number </strong></h3>
                            <h3>UK 234 FZ</h3>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h3><strong>Car Name</strong></h3>
                            <h3>Toyota Fortuner</h3>
                        </li>
                        <li>
                            <h3><strong>Car type</strong></h3>
                            <h3>MVP</h3>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <h3><strong>Driver License</strong></h3>
                            <h3>DL200123UK001</h3>
                        </li>
                    </ul>
                    
                </div>
            </div>
        </div>
    )
}

export default ReportModal