import React, { Children } from 'react'
import Table from 'react-bootstrap/Table';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Loader from '../Loader';

import './dataTable.css'
import Placeholder from 'react-bootstrap/Placeholder';
export const arrowUp = <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
    <path d="M1 5L4.5 1L8 5" stroke="#737373" />
</svg>;
export const arrowDown =
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
        <path d="M8 0.5L4.5 4.5L1 0.5" stroke="#737373" />
    </svg>;

const DataTable = ({ children, loading, header, setColoum, setSort, val }) => {

    return (
        <Table responsive borderless className='data_table'>
            <thead>
                <tr>
                    {header?.map((all_headers, index) => {
                        return (
                            <th onClick={() => setColoum(all_headers?.name)} className={all_headers.name == "" ? "low_opacity" : null} key={index}> <div className='d-flex align-items-center gap-3'>{all_headers?.name}
                                {!val && <span className='d-flex flex-column gap-1'>
                                    <span className='d-inline-flex cursor' onClick={() => setSort(1)}> {arrowUp}</span>
                                    <span className='d-inline-flex cursor' onClick={() => setSort(-1)}>  {arrowDown}</span>
                                </span>}
                            </div>
                            </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                {loading ? <Loader />
                    :
                    children}



            </tbody>
        </Table>
    )
}

export default DataTable;
