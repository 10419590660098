import React, { useState, useEffect } from 'react'
import Button from '../Button/button'
import DataTable from '../Table/dataTable'
import CommonModal from '../Modal/commonModal'
import TermsModal from '../ModalBody/termsModal'
import { useDispatch, useSelector } from 'react-redux'
import { get_all_terms_and_condition } from '../../features/agreementContent/get_all_terms_and_condition_slice'
import { clear_update_term_and_condition_state } from "../../features/agreementContent/update_term_and_condition_slice";
import { clear_get_all_terms_and_condition } from '../../features/agreementContent/get_all_terms_and_condition_slice'
import NoData from '../Nodata/noData'
const TermsAndConditionsTab = ({ term }) => {
    const dispatch = useDispatch()
    const data = useSelector((state) => { return state.get_all_terms })
    const title = "termsAndConditions"
    const header = [{ name: "Sr. No." }, { name: "Terms and Constions" }, { name: "Action" }]
    const pencile = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M5.75977 24.2404L7.25977 18.3004L16.1998 9.36035L20.6398 13.8004L11.6998 22.7404L5.75977 24.2404ZM8.33977 18.9004L7.43977 22.5604L11.0998 21.6604L18.9598 13.8004L16.1998 11.0404L8.33977 18.9004Z" fill="#FF65C3" />
        <path d="M10.6801 22.3797C10.3201 20.8797 9.12012 19.6797 7.62012 19.3197L7.92012 18.1797C9.84012 18.6597 11.3401 20.1597 11.8201 22.0797L10.6801 22.3797Z" fill="#FF65C3" />
        <path d="M17.5784 11.5724L18.4268 12.4208L10.6208 20.2328L9.77241 19.3868L17.5784 11.5724ZM6.59961 23.4002L8.33961 22.9802C8.15961 22.3202 7.67961 21.8402 7.01961 21.6602L6.59961 23.4002ZM20.9996 13.4402L16.5596 9.0002L18.3596 7.2002L18.6596 7.2602C20.8196 7.5602 22.4996 9.2402 22.7996 11.4002L22.8596 11.7002L20.9996 13.4402ZM18.2396 9.0002L20.9996 11.7602L21.5396 11.2202C21.2396 9.8402 20.1596 8.7602 18.7796 8.4602L18.2396 9.0002Z" fill="#FF65C3" />
    </svg>;
    const del = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M7 21C6.45 21 5.979 20.804 5.587 20.412C5.195 20.02 4.99933 19.5493 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.804 20.021 18.412 20.413C18.02 20.805 17.5493 21.0007 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="#FF65C3" />
    </svg>;

    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [id, setId] = useState("")
    const [no_record_found, setNo_record_found] = useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    useEffect(() => {
        if (term) {
            dispatch(get_all_terms_and_condition({ title }))
            dispatch(clear_get_all_terms_and_condition())
        }
    }, [])

    useEffect(() => {
        if (data?.data?.data?.length === 0) {
          setNo_record_found(true)
        } else {
          setNo_record_found(false)
        }
      }, [data])

    return (

        <div className='terms_wrapper'>
            {data?.data?.data?.length >= 1 ? null : <div className='text-end mb-5 pt-4'><Button className="outline_btn" title="Add new" onClick={handleShow} /></div>}
            <DataTable header={header} loading={data?.loading}>
                {data?.data?.data?.map((value, i) => {
                    return (
                        <tr key={i}>
                            <td valign="top">{i + 1}</td>
                            <td valign="top">Terms And Condition</td>
                            <td valign="top"><span className='me-2' onClick={() => { handleShowEdit(); setId(value?._id);dispatch(clear_update_term_and_condition_state()) }}>{pencile}</span>
                            </td>
                        </tr>
                    )
                })}
            </DataTable>
            <NoData data={no_record_found} />
            <CommonModal show={show} handleClose={handleClose} title={"Terms and conditions"}>
                <TermsModal title="termsAndConditions" handleClose={handleClose} />
            </CommonModal>
            <CommonModal show={showEdit} handleCloseEdit={handleCloseEdit} title={"Edit Terms and conditions"}>
                <TermsModal title="termsAndConditions" handleCloseEdit={handleCloseEdit} id={id} />
            </CommonModal>
        </div>
    )
}

export default TermsAndConditionsTab