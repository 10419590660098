import React, { useState, useEffect } from 'react'
import Wrapper from '../Components/Wrapper/wrapper'
import DataTable from '../Components/Table/dataTable'
import { FaStar } from 'react-icons/fa'
import Button from '../Components/Button/button'
import { useDispatch, useSelector } from 'react-redux'
import { get_all_riders } from '../features/Riders/rider_list_slice'
import ReactPaginate from 'react-paginate';
import NoData from '../Components/Nodata/noData'
import Rating from '../Components/Rating/rating'
import { clear_all_rider_details_State } from '../features/Riders/rider_list_slice'
import { RiDeleteBinLine } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import { showDeleteModal } from '../Components/DeleteWarning/deleteWarning'
import EditModal from '../Components/EditModal/editModal'




const Riders = () => {
    const dispatch = useDispatch()
    const [page, setPage] = useState(1)
    const [coloum, setColoum] = useState("Riders ID")
    const [rider_list, setRider_list] = useState([])
    const [search, setSearch] = useState("")
    const [sort, setSort] = useState(1)
    const [no_record_found, setNo_record_found] = useState(false)
    const riders_data = useSelector((state) => { return state.all_rider_list })
    const [handleChnage, setHandleChange] = useState(0)
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [modalFields, setModalFields] = useState([]);
    const [apiEndpoint, setApiEndpoint] = useState('');



    useEffect(() => {
        return () => {
            dispatch(clear_all_rider_details_State())
        };
    }, [])

    useEffect(() => {
        dispatch(get_all_riders({ coloum, page, search, sort }))
    }, [coloum, page, search, sort, handleChnage])

    useEffect(() => {
        setRider_list(riders_data?.data?.data)
        if (riders_data?.data?.message === "Currently no passenger found") {
            setNo_record_found(true)
        } else {
            setNo_record_found(false)
        }
    }, [riders_data])

    const handlePageClick = (e) => {
        const NewPage = e.selected + 1;
        setPage(NewPage)
    }

    const handleDelete = (id, type) => {
        showDeleteModal(id, type, () => {
            setHandleChange(prev => prev + 1);
        })
    }

    const handleEdit = (data) => {
        setCurrentData(data);
        setModalFields([
            { label: 'First Name', name: 'firstName' },
            { label: 'Last Name', name: 'lastName' }
        ]);
        setApiEndpoint(`${process.env.REACT_APP_BACKEND_URL}api/v1/updatePassengerDetails?passengerId=${data._id}`);
        setShowModal(true);
    };



    const header = [{ name: "Riders ID" }, { name: "Rider" }, { name: "Mobile Number" }, { name: "Rating" }, { name: "Total Rides" }, { name: "Total Spend" }, { name: '' },{name:''}]
    return (
        <Wrapper title="Riders">
            <div className='filters'>
                <ul>
                    <li className='search w-25'>
                        <input type="text" name="" id="" placeholder='Search....' onChange={(e) => { setSearch(e.target.value) }} className='pe-5' value={search} />
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path d="M7.1875 13.125C10.4667 13.125 13.125 10.4667 13.125 7.1875C13.125 3.90831 10.4667 1.25 7.1875 1.25C3.90831 1.25 1.25 3.90831 1.25 7.1875C1.25 10.4667 3.90831 13.125 7.1875 13.125Z" stroke="#737373" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.75 13.75L12.5 12.5" stroke="#737373" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </li>
                    {/* <li className='flex-grow-1'>
                        <select name="" id="" value={search} onChange={(e) => setSearch(e.target.value)}>
                            <option >Select Ratings</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                            <option value="4">Four</option>
                            <option value="5">Five</option>
                        </select>
                    </li> */}

                    <li className='text-end'>
                        <Button title="Reset Filter" className="outline_btn" onClick={() => { setPage(page); setColoum("Riders ID"); setSort(1); setSearch("") }}></Button>
                    </li>
                </ul>
            </div>
            <DataTable header={header} loading={riders_data?.loading} setColoum={setColoum} setSort={setSort}>
                {
                    rider_list && rider_list?.map((data, index) => {
                        return (
                            <tr key={index}>
                                <td valign="middle">{data?._id}</td>
                                <td valign="middle">{data?.firstName + " " + data?.lastName}</td>
                                <td valign="middle">+{data?.mobile ? data?.mobile : "Not Available"}</td>
                                <td><Rating value={data?.averageRating ? data?.averageRating : 0} /></td>
                                <td valign="middle">{data?.totalCompletedRides ? data?.totalCompletedRides : 0}</td>
                                <td valign="middle">{data?.totalSpendAmount ? "$" + data?.totalSpendAmount : 0}</td>
                                <td onClick={() => handleDelete(data?.userId, "passenger")}><RiDeleteBinLine /></td>
                                <td onClick={() => handleEdit(data)}> <GrEdit /></td>
                            </tr>
                        )
                    })
                }

            </DataTable>
            <NoData data={no_record_found} />
            {!no_record_found && <div className='custom_paginations'>
                <p className='mb-0'></p>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={riders_data?.data?.totalPages}
                    previousLabel="previous"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>}
            <EditModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                data={currentData}
                fields={modalFields}
                onSave={(updatedData) => setCurrentData(updatedData)}
                apiEndpoint={apiEndpoint}
                handleChange={setHandleChange}
            />
        </Wrapper>
    )
}

export default Riders