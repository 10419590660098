import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from '../Button/button'
import { useDispatch, useSelector } from 'react-redux'
import { clear_get_selected_cancelation_State } from '../../features/Cancellation/getSelecteCancelationReasonSlice'
import { get_selected_reason } from '../../features/Cancellation/getSelecteCancelationReasonSlice'
import { update_cancelation_reason } from '../../features/Cancellation/updateCancelationReasonSlice'
import { toast } from "react-hot-toast";
import { clear_update_cancelation_State } from '../../features/Cancellation/updateCancelationReasonSlice'

const EditReasonModal = ({ handleClose, cancelation_id,get_all_reasons_data }) => {
    const dispatch = useDispatch()
    const selected_reason_data = useSelector((state) => { return state.selected_reason_detail })
    const update_reason = useSelector((state) => {return state.update_selected_cancelation})
    let loading = selected_reason_data?.loading
    const [type,setType] = useState("")
    const [reason,setReason] = useState("")

    useEffect(() => {
        return () => {
          // Your cleanup code or function goes here
          dispatch(clear_get_selected_cancelation_State())
        };
    
      }, [])

    useEffect(() => {
        if (cancelation_id) {
            dispatch(get_selected_reason({ cancelation_id }))
        }
    }, [])

    useEffect(() => {
        setType(selected_reason_data?.data?.type)
        setReason(selected_reason_data?.data?.cancellationReason)
    }, [selected_reason_data])

    useEffect(() => {
         if(update_reason?.isSuccess){
            toast.success("Reason Updated Successfully !!")
            handleClose()
            get_all_reasons_data()
            dispatch(clear_update_cancelation_State())
         }
         if(update_reason?.isError){
            toast.error("Something Went Wrong !!")
         }
    },[update_reason])

    const update_cancelation_details = () => {
        if(type !== "" && reason !== ""){
            dispatch(update_cancelation_reason({type,reason,cancelation_id}))
        }
        else{
            toast.error("All fields are required")
        }
    }
    return (
        <>
            <Row className='m-0 form_wrapper'>
            <Col lg={12}>
                <div className='form-group mb-3'>
                    <label htmlFor="">User Type</label>
                    <select className="form-select" value={type} onChange={(e) => setType(e.target.value)} >
                        <option>Select</option>
                        <option value="driver">Driver</option>
                        <option value="passenger">Passenger</option>
                    </select>
                </div>
            </Col>

            <Col lg={12}>
                <div className='form-group mb-3'>
                    <label htmlFor="">Reason</label>
                    <textarea name="" className='form-control' id="" cols="30" rows="5" value={reason} onChange={(e) => setReason(e.target.value)}></textarea>
                </div>
            </Col>

            <Col lg={12} className='text-center mt-2'>
                <Button title="Cancel" className="outline_btn me-4" onClick={handleClose} />
                <Button title="Submit" className="theme_light" loading={update_reason?.loading} onClick={() => update_cancelation_details()}/>
            </Col>
        </Row>
        </>
    
    )
}

export default EditReasonModal