import React from 'react';

const NoData = ({data}) => {
  return (
    <>
      {data && (
        <div className='no_data text-center pt-5'>
          <img src="/images/nodata.png" alt="" className='img-fluid' />
        </div>
      )}
    </>
  );
};

export default NoData;
