import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const view_driver_all_documents = createAsyncThunk(
    'view_driver_all_documents',
    async ({ id,token }, thunkAPI) => {
        console.log(id,"from slice")
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + token);

            var requestOptions = {
                method: 'GET',
                redirect: 'follow',
                headers: myHeaders,
            };

            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/get-Driver-Profile?id=${id}`, requestOptions)
            if (!response.ok) {
                // Handle non-2xx responses here
                const errorMessage = await response.json();
                throw new Error(errorMessage.message || 'Failed to fetch');
            }

            const result = await response.json();
            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue({
                message: error.message,
            });
        }
    }
);


export const view_driver_documents = createSlice({
    name: "view_driver_documents",
    initialState: {
        data: {},
        isSuccess: false,
        isError: false,
        error: null,
        loading: false
    },
    reducers: {
        clear_driver_verify_state: (state) => {
            state.data = [];
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
  
    extraReducers: {
        [view_driver_all_documents.pending]: (state) => {
            state.loading = true
        },
        [view_driver_all_documents.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.isSuccess = true;
            state.loading = false
        },
        [view_driver_all_documents.rejected]: (state, action) => {
            state.error = action.payload;
            state.isError = true
            state.loading = false
            state.isSuccess = false
        }
    }
})
export const { clear_driver_verify_state } = view_driver_documents.actions;
export default view_driver_documents.reducer;