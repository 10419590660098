import React from 'react'
import './button.css'
import Spinner from 'react-bootstrap/Spinner';
const Button = ({title, onClick, className,loading}) => {
  return (
    <button onClick={onClick} className={`lgo_cmn_btn ${className}`}>
    {loading &&<Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />}
       {!loading && <span> {title}</span>}
    </button>


  )
}

export default Button