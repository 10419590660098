import React from 'react'
import Wrapper from '../Components/Wrapper/wrapper'
import { Row, Col } from 'react-bootstrap'
import Card from '../Components/Card/card'
import './tracking.css'
import Table from 'react-bootstrap/Table';
const Tracking = () => {
    return (
        <Wrapper title="Tracking Bookings">
            <Row>
                <Col lg={3}>
                    <div className='tarckin_sidebar'>
                        <div className='booking_filter mb-2'>
                            <input type="text" placeholder="Search" />
                            <select name="" id="">
                                <option value="">Status</option>
                            </select>
                        </div>
                        <Card className="mb-3">

                            <ul className='trip_haeder'>
                                <li>TRPAA001</li> <li>Active Trip</li>
                            </ul>
                            <h6>Sedan</h6>
                            <ul className='tarchLocation'>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="7.5" fill="#FF65C3" />
                                        <circle cx="12" cy="12" r="11.25" stroke="#FF65C3" strokeWidth="1.5" />
                                    </svg>
                                    Antonietta Heights, Paxton 57353
                                </li>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 35" fill="none">
                                        <path d="M13.8545 33.8333C14.1539 33.8333 14.4092 33.7295 14.5719 33.6495C14.7516 33.5612 14.9275 33.4466 15.0915 33.3264C15.4205 33.0853 15.7866 32.7587 16.1651 32.3895C16.9268 31.6468 17.8234 30.6482 18.7118 29.5952C20.4815 27.4976 22.3038 25.0822 23.0488 23.8736C23.1096 23.775 23.1698 23.6774 23.2295 23.5807C25.294 20.2347 26.709 17.9413 26.709 13.8545C26.709 6.75517 20.9539 1 13.8545 1C6.75517 1 1 6.75517 1 13.8545C1 18.0676 2.38494 20.3483 4.55423 23.873C5.34959 25.1653 7.20122 27.5833 8.98606 29.6615C9.88233 30.7052 10.7837 31.6889 11.5484 32.4192C11.9286 32.7822 12.2957 33.1027 12.6253 33.339C12.7898 33.4569 12.9656 33.569 13.1449 33.6551C13.3077 33.7333 13.5601 33.8333 13.8545 33.8333Z" fill="#FF65C3" stroke="white" strokeWidth="2" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8545 19.7817C17.128 19.7817 19.7818 17.128 19.7818 13.8545C19.7818 10.5809 17.128 7.92722 13.8545 7.92722C10.581 7.92722 7.92725 10.5809 7.92725 13.8545C7.92725 17.128 10.581 19.7817 13.8545 19.7817Z" fill="white" />
                                    </svg>
                                    Gavin Lake, 08789 Bennett Lake
                                </li>
                            </ul>
                        </Card>
                        <Card>

                            <ul className='trip_haeder'>
                                <li>TRPAA001</li> <li>Active Trip</li>
                            </ul>
                            <h6>Sedan</h6>
                            <ul className='tarchLocation'>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="7.5" fill="#FF65C3" />
                                        <circle cx="12" cy="12" r="11.25" stroke="#FF65C3" strokeWidth="1.5" />
                                    </svg>
                                    Antonietta Heights, Paxton 57353
                                </li>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 35" fill="none">
                                        <path d="M13.8545 33.8333C14.1539 33.8333 14.4092 33.7295 14.5719 33.6495C14.7516 33.5612 14.9275 33.4466 15.0915 33.3264C15.4205 33.0853 15.7866 32.7587 16.1651 32.3895C16.9268 31.6468 17.8234 30.6482 18.7118 29.5952C20.4815 27.4976 22.3038 25.0822 23.0488 23.8736C23.1096 23.775 23.1698 23.6774 23.2295 23.5807C25.294 20.2347 26.709 17.9413 26.709 13.8545C26.709 6.75517 20.9539 1 13.8545 1C6.75517 1 1 6.75517 1 13.8545C1 18.0676 2.38494 20.3483 4.55423 23.873C5.34959 25.1653 7.20122 27.5833 8.98606 29.6615C9.88233 30.7052 10.7837 31.6889 11.5484 32.4192C11.9286 32.7822 12.2957 33.1027 12.6253 33.339C12.7898 33.4569 12.9656 33.569 13.1449 33.6551C13.3077 33.7333 13.5601 33.8333 13.8545 33.8333Z" fill="#FF65C3" stroke="white" strokeWidth="2" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8545 19.7817C17.128 19.7817 19.7818 17.128 19.7818 13.8545C19.7818 10.5809 17.128 7.92722 13.8545 7.92722C10.581 7.92722 7.92725 10.5809 7.92725 13.8545C7.92725 17.128 10.581 19.7817 13.8545 19.7817Z" fill="white" />
                                    </svg>
                                    Gavin Lake, 08789 Bennett Lake
                                </li>
                            </ul>
                        </Card>

                    </div>
                </Col>
                <Col lg={9}>
                    <div className='tracking_map position-relative'>
                        <img src="/images/maps.png" className='img-fluid' alt="" />
                        <div className='tracking_table'>
                            <Table responsive="md" borderless className='mb-0'>
                                <thead className='border-bottom'>
                                    <th>Trip Details</th>
                                    <th>Driver Info</th>
                                    <th>Rider</th>
                                    <th>Vehicle Details</th>
                                    <th>Payment Details</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p className='sub_title'>Trip ID</p>
                                            <p className='mb-2'>TRPAA002</p>
                                        </td>
                                        <td>
                                            <p className='sub_title'>Booking Category</p>
                                            <p className='mb-2'>From Passenger</p>
                                        </td>
                                        <td>
                                            <p className='sub_title'>Ride Now?</p>
                                            <p className='mb-2'>No</p>
                                        </td>
                                        <td>
                                            <p className='sub_title'>Pre-Booking for</p>
                                            <p className='mb-2'>14 Sept 2023 <br />
                                                at 08:30:00</p>
                                        </td>
                                        <td>
                                            <p className='sub_title'>Kilometres</p>
                                            <p className='mb-2'>56</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className='sub_title'>Pick Up</p>
                                            <p className='mb-2'>Antonietta
                                                Heights,
                                                Paxton 57373</p>
                                        </td>
                                        <td>
                                            <p className='sub_title'>Drop Off</p>
                                            <p className='mb-2'>Gavin Lake,
                                                08789
                                                Bennett Lake</p>
                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
        </Wrapper>
    )
}

export default Tracking