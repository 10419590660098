import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditModal = ({ show, handleClose, data, fields, onSave, apiEndpoint, handleChange }) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');
    const lastKey = keys.pop();

    
    let nestedData = { ...formData };

    
    let temp = nestedData;
    keys.forEach((key) => {
      temp[key] = { ...temp[key] };
      temp = temp[key];
    });

 
    temp[lastKey] = value;

   
    setFormData(nestedData);
  };

  const handleSave = () => {
    let token = localStorage.getItem('token');
    fetch(apiEndpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.type === 'success') {
          handleClose(false);
          handleChange((prev) => prev + 1);
          onSave(data);
        }
      })
      .catch(error => console.error('Error:', error));
  };

  const getFieldValue = (fieldName) => {
    return fieldName.split('.').reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : '', formData);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {fields.map((field, index) => (
            <Form.Group key={index}>
              <Form.Label>{field.label}</Form.Label>
              <Form.Control
                type={field.type || 'text'}
                name={field.name}
                value={getFieldValue(field.name)}
                onChange={handleChanges}
              />
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
