import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from '../Button/button'
import { add_new_question } from '../../features/FAQ\'s/add_new_question_slice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-hot-toast";
import { clear_add_question_state } from '../../features/FAQ\'s/add_new_question_slice'
import { get_all_questions } from '../../features/FAQ\'s/get_all_questions_slice'
import { get_single_question } from '../../features/FAQ\'s/get_single_question_slice'
import { clear_get_single_question_state } from '../../features/FAQ\'s/get_single_question_slice'
import { update_question } from '../../features/FAQ\'s/update_question_slice'
import { clear_update_question_state } from '../../features/FAQ\'s/update_question_slice'
const FaqsModal = ({ handleClose, id }) => {
    const dispatch = useDispatch()
    const [question, setQuestion] = useState("")
    const [answer, setAnswer] = useState("")
    const add_data = useSelector((state) => { return state.add_question })
    const edit_data = useSelector((state) => { return state.get_single_FAQ })
    const updated_data = useSelector((state) => {return state.update_faq})
    console.log(updated_data,"this is the updated data")

    useEffect(() => {
        return () => {
            dispatch(clear_get_single_question_state())
        };
    }, [])

    const handleSave = () => {
        if (question !== "" && answer !== "") {
            if (id) {
                dispatch(update_question({ id, question, answer }))
            } else {
                dispatch(add_new_question({ question, answer }))
            }
        } else {
            toast.error("All Fields Are Mandatory !!")
        }
    }

    useEffect(() => {
        if (add_data?.isSuccess) {
            handleClose()
            toast.success("Question Added Successfully !!")
            dispatch(clear_add_question_state())
            dispatch(get_all_questions())
        }
    }, [add_data])

    useEffect(() => {
        if (id) {
            dispatch(get_single_question({ id }))
        }
    }, [id])

    useEffect(() => {
        if (edit_data?.isSuccess) {
            setQuestion(edit_data?.data?.isFaqExist?.question)
            setAnswer(edit_data?.data?.isFaqExist?.answer)
        }
    }, [edit_data])

    useEffect(() => {
        if(updated_data?.isSuccess){
            toast.success("Updated Successfully !!")
            handleClose()
            dispatch(clear_update_question_state())
            dispatch(get_all_questions())
        }
    },[updated_data])
    return (
        <div>
            <Row className='m-0 form_wrapper'>
                <Col lg={12}>
                    <div className='form-group mb-3'>
                        <label htmlFor="" className='mt-0'>Question</label>
                        <input type="text" className='form-control' onChange={(e) => setQuestion(e.target.value)} value={question} />
                    </div>
                </Col>
                <Col lg={12}>
                    <div className='form-group mb-3'>
                        <label htmlFor="" className='mt-0'>Answer</label>
                        <textarea name="" className='form-control' id="" cols="30" rows="5" onChange={(e) => setAnswer(e.target.value)} value={answer}></textarea>
                    </div>
                </Col>

                <Col lg={12} className='text-center mt-2'>
                    <Button title="Cancel" className="outline_btn me-4" onClick={() => handleClose()} />
                    <Button title={id ? "Update" : "Submit"} className="theme_light" onClick={() => handleSave()} loading={add_data?.loading ? add_data?.loading : updated_data?.loading} />
                </Col>
            </Row>

        </div>
    )
}

export default FaqsModal