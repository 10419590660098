import React from 'react'

const Loader = () => {
    return (
            <div className="loader_outer">
                <img className='loader_image' src="/images/loader.gif" />
            </div>
    )
}

export default Loader