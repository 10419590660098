import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const verify_the_driver = createAsyncThunk("verify_the_driver", async ({ type, id, token }, thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/driverRequestResponse?id=${id}&responseType=${type}`, requestOptions)
        if (!response.ok) {
            // Handle non-2xx responses here
            const errorMessage = await response.json();
            throw new Error(errorMessage.message || 'Failed to fetch');
        }

        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const verify_driver = createSlice({
    name: "verify_driver",
    initialState: {
        message: "",
        isError: false,
        isSuccess: false,
        error: null,
        loading: false
    },
    reducers: {
        clear_driver_verified_state: (state) => {
            state.message = "";
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers:{
        [verify_the_driver?.pending] : (state) => {
            state.loading = true
        },
        [verify_the_driver?.fulfilled] : (state,action) => {
            state.message = action.payload;
            state.loading = false;
            state.isSuccess = true
        },
        [verify_the_driver?.rejected] : (state,action) => {
            state.isError = true;
            state.error = action.payload;
            state.loading = false;
        }
    }
})
export const { clear_driver_verified_state } = verify_driver.actions;
export default verify_driver.reducer;