import React, { useState, useEffect } from 'react'
import Wrapper from '../Components/Wrapper/wrapper'
import DataTable from '../Components/Table/dataTable'
import Button from '../Components/Button/button'
import { useDispatch, useSelector } from 'react-redux'
import { get_all_bookings } from '../features/Bookings/all_bookings_slice'
import Moment from "react-moment";
import ReactPaginate from 'react-paginate';
import NoData from '../Components/Nodata/noData'
import { useNavigate } from 'react-router-dom'
import { FaRegEye } from "react-icons/fa";
const Bookings = () => {
  const dispatch = useDispatch()
  const booking_data = useSelector((state) => { return state.booking_list })
  const [show, setShow] = useState(false); //show modal state
  const handleClose = () => setShow(false); // modal close function
  const handleShow = () => setShow(true); // modal show function
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [coloum, setColoum] = useState("Rider")
  const [sort, setSort] = useState(1)
  const [bookings_list, setBookings_list] = useState([])
  const [no_record_found, setNo_record_found] = useState(false)
  const [date, setDate] = useState("")
  const header = [{ name: "Sr No" }, { name: "Rider" }, { name: "Booking Date" }, { name: "Driver" }, { name: "Vehicle Type" }, { name: "Status" }, { name: "Price" }, { name: "" }]

  useEffect(() => {
    dispatch(get_all_bookings({ page, search, coloum, sort, date }))
  }, [page, search, coloum, sort, date])

  useEffect(() => {
    setBookings_list(booking_data?.data?.data)
    if (booking_data?.data?.message === "no booking found") {
      setNo_record_found(true)
    } else {
      setNo_record_found(false)
    }
  }, [booking_data])

  const handlePageClick = (e) => {
    const NewPage = e.selected + 1;
    setPage(NewPage)
  }
  const navigate = useNavigate()
  return (
    <Wrapper title="Bookings">
      <div className='filters'>
        <ul>
          <li className='search search w-25'>
            <input type="text" name="" id="" placeholder='Search....' value={search} onChange={(e) => { setSearch(e.target.value) }} />
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
              <path d="M7.1875 13.125C10.4667 13.125 13.125 10.4667 13.125 7.1875C13.125 3.90831 10.4667 1.25 7.1875 1.25C3.90831 1.25 1.25 3.90831 1.25 7.1875C1.25 10.4667 3.90831 13.125 7.1875 13.125Z" stroke="#737373" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M13.75 13.75L12.5 12.5" stroke="#737373" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </li>
          {/* <li>
            <select name="" id="">
              <option value="">Ratings</option>
            </select>
          </li> */}
          <li className='search w-25'>
            <input type="date" onChange={(e) => setDate(e.target.value)} value={date} />
          </li>
          <li className='ms-auto text-end'>
            <Button title="Reset Filter" className="outline_btn" onClick={() => { setPage(page); setSearch(""); setDate(""); setSort(1); setColoum("Rider") }}></Button>
          </li>
        </ul>
      </div>
      <DataTable header={header} loading={booking_data?.loading} setColoum={setColoum} setSort={setSort}>
        {
          bookings_list?.map((data, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data?.passengerFirstname + " " + data?.passengerLastname}</td>
                <td>
                  <Moment format=' Do MMMM YYYY, h:mm:ss a'>
                    {data?.createdAt}
                  </Moment></td>
                <td>{data?.driverFirstname + " " + data?.driverLastName}</td>
                <td>{data?.vehicleType}</td>
                <td>{data?.boookingStatus}</td>
                <td>{data?.price !== 0 ? "$" + Number(data?.price)?.toFixed(2) : "0"}</td>
                <td><span onClick={() => navigate('/booking/BookingDetails', { state: { booking_id: data?._id } })}><FaRegEye /></span></td>
              </tr>
            )
          })
        }

      </DataTable>
      <NoData data={no_record_found} />
      {!no_record_found && <div className='custom_paginations'>
        <p className='mb-0'></p>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={booking_data?.data?.totalPages}
          previousLabel="previous"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>}

    </Wrapper>

  )
}

export default Bookings