import React, { useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './wrapper.css';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { FaCircleUser } from "react-icons/fa6";

import { RiLockPasswordFill } from "react-icons/ri";
import { CiLogout } from "react-icons/ci";
import CommonModal from '../Modal/commonModal';
import ChangePasswordModal from '../ModalBody/changePasswordModal';
const Wrapper = ({ children, title }) => {
    const navigation = useNavigate();
    const token = localStorage.getItem('token')
    console.log(token, "this is the token")

    useEffect(() => {
        if (!token) {
            navigation("/")
        }

    }, [])

    const handleLogout = () => {
        localStorage.clear();
        navigation("/");
    }
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div>
            <CommonModal size="md" show={show} handleClose={handleClose} title="Change Password">
                <ChangePasswordModal handleClose={handleClose} />
            </CommonModal>
            <Navbar collapseOnSelect expand="lg" className="ldo_bg">

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <p className='mb-0 client_name flex-grow-1'>Welcome Back, Admin</p>
                    <Dropdown className='user_dropdown'>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <FaCircleUser size={30} /> <span className='text-start'>Admin</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" viewBox="0 0 11 7" fill="none">
                                <path d="M1 1L5.5 6L10 1" stroke="white" />
                            </svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleShow}><RiLockPasswordFill className='me-2' />Change Password</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleLogout()}><CiLogout className='me-2' />Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 6.43994V9.76994" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                        <path d="M12.0203 1.99994C8.34026 1.99994 5.36026 4.97994 5.36026 8.65994V10.7599C5.36026 11.4399 5.08026 12.4599 4.73026 13.0399L3.46026 15.1599C2.68026 16.4699 3.22026 17.9299 4.66026 18.4099C9.44026 19.9999 14.6103 19.9999 19.3903 18.4099C20.7403 17.9599 21.3203 16.3799 20.5903 15.1599L19.3203 13.0399C18.9703 12.4599 18.6903 11.4299 18.6903 10.7599V8.65994C18.6803 4.99994 15.6803 1.99994 12.0203 1.99994Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
                        <path d="M15.3298 18.8199C15.3298 20.6499 13.8298 22.1499 11.9998 22.1499C11.0898 22.1499 10.2498 21.7699 9.6498 21.1699C9.0498 20.5699 8.6698 19.7299 8.6698 18.8199" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
                    </svg> */}

                </Navbar.Collapse>
            </Navbar>
            <aside>
                <div className='side_bar ldo_bg'>
                    <div className='log_image' onClick={() => navigation('/dashboard')}>
                        <img src="/images/Neutral_skincare_Logo.png" alt="ldy_logo" style={{ cursor: "pointer" }} />
                    </div>
                    <ul>
                        <li>
                            <NavLink to="/dashboard">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 10H7C9 10 10 9 10 7V5C10 3 9 2 7 2H5C3 2 2 3 2 5V7C2 9 3 10 5 10Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17 22H19C21 22 22 21 22 19V17C22 15 21 14 19 14H17C15 14 14 15 14 17V19C14 21 15 22 17 22Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/booking">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M8 2V5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16 2V5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M3.5 9.08997H20.5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.6947 13.7H15.7037" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.6947 16.7H15.7037" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.9955 13.7H12.0045" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.9955 16.7H12.0045" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8.29431 13.7H8.30329" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8.29431 16.7H8.30329" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Booking</span>
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/rider">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12.1238 10.6142C12.0323 10.6051 11.9225 10.6051 11.8219 10.6142C9.64471 10.541 7.91577 8.75719 7.91577 6.56171C7.91577 4.32048 9.72705 2.50006 11.9774 2.50006C14.2186 2.50006 16.0391 4.32048 16.0391 6.56171C16.0299 8.75719 14.301 10.541 12.1238 10.6142Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.8412 4.32965C19.6158 4.32965 21.0429 5.76586 21.0429 7.5314C21.0429 9.26034 19.6707 10.6691 17.9601 10.7331C17.8869 10.724 17.8046 10.724 17.7222 10.7331" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5.62882 4.32965C3.85414 4.32965 2.42707 5.76586 2.42707 7.5314C2.42707 9.26034 3.79925 10.6691 5.5099 10.7331C5.58308 10.724 5.66541 10.724 5.74774 10.7331" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M7.54986 13.9897C5.33608 15.4717 5.33608 17.8867 7.54986 19.3595C10.0655 21.0427 14.1912 21.0427 16.7069 19.3595C18.9206 17.8776 18.9206 15.4625 16.7069 13.9897C14.2003 12.3157 10.0747 12.3157 7.54986 13.9897Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19.6067 18.9662C20.2653 18.829 20.8874 18.5637 21.3997 18.1703C22.8267 17.1 22.8267 15.3345 21.3997 14.2642C20.8965 13.88 20.2836 13.6238 19.6341 13.4775" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M3.86328 18.9662C3.20464 18.829 2.58258 18.5637 2.0703 18.1703C0.64324 17.1 0.64324 15.3345 2.0703 14.2642C2.57344 13.88 3.18634 13.6238 3.83584 13.4775" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Riders</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/drivers">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18 18.86H17.24C16.44 18.86 15.68 19.17 15.12 19.73L13.41 21.42C12.63 22.19 11.36 22.19 10.58 21.42L8.87 19.73C8.31 19.17 7.54 18.86 6.75 18.86H6C4.34 18.86 3 17.53 3 15.89V4.97998C3 3.33998 4.34 2.01001 6 2.01001H18C19.66 2.01001 21 3.33998 21 4.97998V15.89C21 17.52 19.66 18.86 18 18.86Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 10C13.2868 10 14.33 8.95681 14.33 7.66998C14.33 6.38316 13.2868 5.34003 12 5.34003C10.7132 5.34003 9.66998 6.38316 9.66998 7.66998C9.66998 8.95681 10.7132 10 12 10Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16 15.66C16 13.86 14.21 12.4 12 12.4C9.79 12.4 8 13.86 8 15.66" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Drivers</span>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/tracking">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.5 11.3V7.04001C20.5 3.01001 19.56 2 15.78 2H8.22C4.44 2 3.5 3.01001 3.5 7.04001V18.3C3.5 20.96 4.96001 21.59 6.73001 19.69L6.73999 19.68C7.55999 18.81 8.80999 18.88 9.51999 19.83L10.53 21.18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.2 21.4C19.9673 21.4 21.4 19.9673 21.4 18.2C21.4 16.4327 19.9673 15 18.2 15C16.4327 15 15 16.4327 15 18.2C15 19.9673 16.4327 21.4 18.2 21.4Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M22 22L21 21" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 7H16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 11H15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Tracking Bookings</span>
                            </NavLink>
                        </li> */}
                        {/* <li>
                            <NavLink to="/ddd">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.5 11.3V7.04001C20.5 3.01001 19.56 2 15.78 2H8.22C4.44 2 3.5 3.01001 3.5 7.04001V18.3C3.5 20.96 4.96001 21.59 6.73001 19.69L6.73999 19.68C7.55999 18.81 8.80999 18.88 9.51999 19.83L10.53 21.18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M8 7H16" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 11H15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.211 14.7703L14.671 18.3103C14.531 18.4503 14.401 18.7103 14.371 18.9003L14.181 20.2503C14.111 20.7403 14.451 21.0803 14.941 21.0103L16.291 20.8203C16.481 20.7903 16.751 20.6603 16.881 20.5203L20.421 16.9803C21.031 16.3703 21.321 15.6603 20.421 14.7603C19.531 13.8703 18.821 14.1603 18.211 14.7703Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.6992 15.2803C17.9992 16.3603 18.8392 17.2003 19.9192 17.5003" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Reports</span>
                            </NavLink>
                        </li> */}

                        <li>
                            <NavLink to="/booking">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M7.47998 18.35L10.58 20.75C10.98 21.15 11.88 21.35 12.48 21.35H16.28C17.48 21.35 18.78 20.45 19.08 19.25L21.48 11.95C21.98 10.55 21.08 9.35 19.58 9.35H15.58C14.98 9.35 14.48 8.85 14.58 8.15L15.08 4.95C15.28 4.05 14.68 3.05 13.78 2.75C12.98 2.45 11.98 2.85 11.58 3.45L7.47998 9.55" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
                                    <path d="M2.38 18.35V8.54999C2.38 7.14999 2.98 6.64999 4.38 6.64999H5.38C6.78 6.64999 7.38 7.14999 7.38 8.54999V18.35C7.38 19.75 6.78 20.25 5.38 20.25H4.38C2.98 20.25 2.38 19.75 2.38 18.35Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Bookings</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/driver_request">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M3.41003 22C3.41003 18.13 7.26003 15 12 15C12.96 15 13.89 15.13 14.76 15.37" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M22 18C22 18.75 21.79 19.46 21.42 20.06C21.21 20.42 20.94 20.74 20.63 21C19.93 21.63 19.01 22 18 22C16.54 22 15.27 21.22 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.74 14.58 15.61 15.5 14.88C16.19 14.33 17.06 14 18 14C20.21 14 22 15.79 22 18Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.44 18L17.43 18.99L19.56 17.02" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Request</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/delete_requests">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M3.41003 22C3.41003 18.13 7.26003 15 12 15C12.96 15 13.89 15.13 14.76 15.37" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M22 18C22 18.75 21.79 19.46 21.42 20.06C21.21 20.42 20.94 20.74 20.63 21C19.93 21.63 19.01 22 18 22C16.54 22 15.27 21.22 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.74 14.58 15.61 15.5 14.88C16.19 14.33 17.06 14 18 14C20.21 14 22 15.79 22 18Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.44 18L17.43 18.99L19.56 17.02" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span> Delete Requests</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/wallet">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M17.7514 7.04997C17.5114 7.00997 17.2614 6.99998 17.0014 6.99998H7.00141C6.72141 6.99998 6.45141 7.01998 6.19141 7.05998C6.33141 6.77998 6.53141 6.52001 6.77141 6.28001L10.0214 3.02C11.3914 1.66 13.6114 1.66 14.9814 3.02L16.7314 4.78996C17.3714 5.41996 17.7114 6.21997 17.7514 7.04997Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M9 19C9 19.75 8.79 20.46 8.42 21.06C7.73 22.22 6.46 23 5 23C3.54 23 2.27 22.22 1.58 21.06C1.21 20.46 1 19.75 1 19C1 16.79 2.79 15 5 15C7.21 15 9 16.79 9 19Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M6.49172 18.9795H3.51172" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5 17.5195V20.5095" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22 12V17C22 20 20 22 17 22H7.63C7.94 21.74 8.21 21.42 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15C3.8 15 2.73 15.53 2 16.36V12C2 9.28 3.64 7.38 6.19 7.06C6.45 7.02 6.72 7 7 7H17C17.26 7 17.51 7.00999 17.75 7.04999C20.33 7.34999 22 9.26 22 12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M22 12.5H19C17.9 12.5 17 13.4 17 14.5C17 15.6 17.9 16.5 19 16.5H22" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <span>Wallet</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/settings">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M14.2617 15.4385H9.26172" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.7617 12.998V17.998" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12.66 2.51814L12.63 2.58814L9.73002 9.31814H6.88002C6.20002 9.31814 5.55002 9.45814 4.96002 9.70814L6.71002 5.52814L6.75002 5.42814L6.82002 5.26814C6.84002 5.20814 6.86002 5.14814 6.89002 5.09814C8.20002 2.06814 9.68002 1.37814 12.66 2.51814Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18.05 9.51813C17.6 9.37813 17.12 9.31813 16.64 9.31813H9.72998L12.63 2.58813L12.66 2.51813C12.81 2.56813 12.95 2.63813 13.1 2.69813L15.31 3.62813C16.54 4.13813 17.4 4.66813 17.92 5.30813C18.02 5.42813 18.1 5.53813 18.17 5.66813C18.26 5.80813 18.33 5.94813 18.37 6.09813C18.41 6.18813 18.44 6.27813 18.46 6.35813C18.73 7.19813 18.57 8.22813 18.05 9.51813Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M21.5217 14.1984V16.1484C21.5217 16.3484 21.5117 16.5484 21.5017 16.7484C21.3117 20.2384 19.3617 21.9984 15.6617 21.9984H7.86172C7.62172 21.9984 7.38172 21.9784 7.15172 21.9484C3.97172 21.7384 2.27172 20.0384 2.06172 16.8584C2.03172 16.6284 2.01172 16.3884 2.01172 16.1484V14.1984C2.01172 12.1884 3.23172 10.4584 4.97172 9.70836C5.57172 9.45836 6.21172 9.31836 6.89172 9.31836H16.6517C17.1417 9.31836 17.6217 9.38836 18.0617 9.51836C20.0517 10.1284 21.5217 11.9884 21.5217 14.1984Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.71 5.52814L4.96 9.70814C3.22 10.4581 2 12.1881 2 14.1981V11.2681C2 8.42814 4.02 6.05814 6.71 5.52814Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M21.5186 11.2677V14.1977C21.5186 11.9977 20.0586 10.1277 18.0586 9.52766C18.5786 8.22766 18.7286 7.20766 18.4786 6.35766C18.4586 6.26766 18.4286 6.17766 18.3886 6.09766C20.2486 7.05766 21.5186 9.02766 21.5186 11.2677Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Settings</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/help">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M10.9699 8.265C10.7604 8.40902 10.5924 8.60559 10.4829 8.835C10.3899 9.00564 10.2344 9.13355 10.049 9.19178C9.86359 9.25001 9.66284 9.23402 9.48901 9.14717C9.31518 9.06032 9.18185 8.9094 9.11709 8.72619C9.05233 8.54298 9.06121 8.34179 9.14185 8.165C9.34185 7.763 9.65485 7.339 10.1389 7.017C10.6269 6.69 11.2439 6.5 11.9999 6.5C12.6579 6.5 13.3689 6.695 13.9339 7.119C14.2488 7.34926 14.5043 7.65142 14.6789 8.00034C14.8536 8.34926 14.9423 8.73484 14.9379 9.125C14.9379 10.158 14.4249 10.845 13.9109 11.34C13.7209 11.523 13.5119 11.698 13.3319 11.848L13.1849 11.971C13.0306 12.0972 12.8853 12.2338 12.7499 12.38V13.75C12.7499 13.9489 12.6708 14.1397 12.5302 14.2803C12.3895 14.421 12.1988 14.5 11.9999 14.5C11.8009 14.5 11.6102 14.421 11.4695 14.2803C11.3289 14.1397 11.2499 13.9489 11.2499 13.75V12.277C11.2499 12.04 11.3169 11.773 11.4969 11.541C11.7169 11.261 11.9829 11.024 12.2149 10.827L12.3979 10.674L12.3989 10.673C12.5709 10.53 12.7229 10.403 12.8689 10.261C13.2369 9.906 13.4379 9.585 13.4379 9.125C13.4423 8.96775 13.4077 8.81184 13.3372 8.6712C13.2667 8.53056 13.1625 8.40956 13.0339 8.319C12.7659 8.118 12.3839 8 11.9999 8C11.5059 8 11.1859 8.121 10.9699 8.265ZM12.9999 17C12.9999 17.2652 12.8945 17.5196 12.707 17.7071C12.5194 17.8946 12.2651 18 11.9999 18C11.7346 18 11.4803 17.8946 11.2927 17.7071C11.1052 17.5196 10.9999 17.2652 10.9999 17C10.9999 16.7348 11.1052 16.4804 11.2927 16.2929C11.4803 16.1054 11.7346 16 11.9999 16C12.2651 16 12.5194 16.1054 12.707 16.2929C12.8945 16.4804 12.9999 16.7348 12.9999 17Z" fill="white" />
                                    <path d="M12 1C18.075 1 23 5.925 23 12C23 18.075 18.075 23 12 23C5.925 23 1 18.075 1 12C1 5.925 5.925 1 12 1ZM2.5 12C2.5 14.5196 3.50089 16.9359 5.28249 18.7175C7.06408 20.4991 9.48044 21.5 12 21.5C14.5196 21.5 16.9359 20.4991 18.7175 18.7175C20.4991 16.9359 21.5 14.5196 21.5 12C21.5 9.48044 20.4991 7.06408 18.7175 5.28249C16.9359 3.50089 14.5196 2.5 12 2.5C9.48044 2.5 7.06408 3.50089 5.28249 5.28249C3.50089 7.06408 2.5 9.48044 2.5 12Z" fill="white" />
                                </svg>
                                <span>Help</span>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/ddd">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M2 10H22" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11.5483 20.4998H6.43829C2.88829 20.4998 1.98828 19.6198 1.98828 16.1098V7.88977C1.98828 4.70977 2.72831 3.68977 5.51831 3.52977C5.79831 3.51977 6.10829 3.50977 6.43829 3.50977H17.5483C21.0983 3.50977 21.9983 4.38977 21.9983 7.89977V12.3098" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6 16H10" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M22 18C22 18.75 21.79 19.46 21.42 20.06C20.73 21.22 19.46 22 18 22C16.54 22 15.27 21.22 14.58 20.06C14.21 19.46 14 18.75 14 18C14 15.79 15.79 14 18 14C20.21 14 22 15.79 22 18Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M16.4414 17.9995L17.4314 18.9895L19.5614 17.0195" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Payment</span>
                            </NavLink>
                        </li> */}
                        <li className='log_out' onClick={() => handleLogout()}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M8.90002 8.06001C9.21002 4.46001 11.06 2.99001 15.11 2.99001H15.24C19.71 2.99001 21.5 4.78001 21.5 9.25001V15.77C21.5 20.24 19.71 22.03 15.24 22.03H15.11C11.09 22.03 9.24002 20.58 8.91002 17.04" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15 12.5H3.62" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5.85 9.14999L2.5 12.5L5.85 15.85" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>Logout</span>

                        </li>

                    </ul>
                </div>
            </aside>
            <main>
                <h3 className='cmn_heading'>{title}</h3>
                {children}
            </main>
        </div>
    )
}

export default Wrapper