import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const creditAndDebit = createAsyncThunk("creditAndDebit", async ({ type, user_id, paymentType, price, reason }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
        "userType": type,
        "amount": price,
        "reason": reason,
        "paymentType": paymentType,
        "paymentFrom": "admin"
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/walletTransaction?userTypeId=${user_id}`, requestOptions);
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }

});

export const credit_and_debit = createSlice({
    name: "credit_and_debit",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_credit_debit_state: (state) => {
            state.data = [];
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(creditAndDebit.pending, (state) => {
            state.loading = true
        })
            .addCase(creditAndDebit.fulfilled, (state, action) => {
                state.data = action.payload
                state.isSuccess = true
                state.loading = true
                state.isError = false
            })
            .addCase(creditAndDebit.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.loading = false
                state.isSuccess = false
            })
    }
})
export const { clear_credit_debit_state } = credit_and_debit.actions;
export default credit_and_debit.reducer;