import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const update_question = createAsyncThunk("update_question", async ({ id, question, answer }, thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var raw = JSON.stringify({
            "question": question,
            "answer": answer
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/updateFaq?id=${id}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            throw new Error(errorMessage.message);
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const updateQuestion = createSlice({
    name: "updateQuestion",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_update_question_state: (state) => {
            state.data = []
            state.isError = false
            state.isSuccess = false
            state.loading = false
            state.error = null
            return state
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(update_question.pending, (state) => {
                state.loading = true
            })
            .addCase(update_question.fulfilled, (state, action) => {
                state.data = action.payload
                state.isError = false
                state.isSuccess = true
                state.loading = false
            })
            .addCase(update_question.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const { clear_update_question_state } = updateQuestion.actions
export default updateQuestion.reducer