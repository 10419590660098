import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const get_all_currencies = createAsyncThunk("get_all_currencies", async (thunkAPI) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    try {
        const response = await fetch("https://open.er-api.com/v6/latest", requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const all_currencies = createSlice({
    name: "all_currencies",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(get_all_currencies.pending, (state) => {
            state.loading = true
        })
            .addCase(get_all_currencies.fulfilled, (state, action) => {
                state.data = action.payload
                state.isError = false
                state.isSuccess = true
                state.loading = false
            })
            .addCase(get_all_currencies.rejected, (state, action) => {
                state.isError = true
                state.error = action.payload
                state.loading = false
                state.isSuccess = false
            })
    }
})

export default all_currencies.reducer