import React, { useState, useEffect } from 'react'
import CommonModal from '../Modal/commonModal'
import ReasonModal from '../ModalBody/resonModal'
import EditReasonModal from '../ModalBody/editReasonModal'
import Button from '../Button/button'
import DataTable from '../Table/dataTable'
import { useDispatch, useSelector } from 'react-redux'
import { get_all_reasons } from '../../features/Cancellation/getAllCancelationReasonsSlice'
import ConfirmModal from '../ModalBody/confirmModal'
import delete_cancelation_reason from '../../features/Cancellation/deleteCancelationReasonSlice'
import clear_delete_cancelation_State from '../../features/Cancellation/deleteCancelationReasonSlice'
import { clear_get_all_cancelation_State } from '../../features/Cancellation/getAllCancelationReasonsSlice'

const CancellationTab = () => {
    const dispatch = useDispatch()
    const [reason_type, setReason_type] = useState("passenger")
    const [reasons, setReasons] = useState([])
    const all_reasons = useSelector((state) => { return state.all_cancelation_reason })
    const delete_result = useSelector((state) => { return state.delete_cancelation_data })
    // const [vehicle_id, setVehicle_id] = useState(null)
    const [cancelation_id, setCancelation_id] = useState(null)

    useEffect(() => {
        return () => {
            // Your cleanup code or function goes here
            dispatch(clear_get_all_cancelation_State())
        };

    }, [])

    const get_all_reasons_data = () => {
        dispatch(get_all_reasons({ reason_type }))
    }

    useEffect(() => {
        get_all_reasons_data()
    }, [reason_type])

    useEffect(() => {
        setReasons(all_reasons?.data)
    }, [all_reasons])

    const [cancellationReason, setCencellationReason] = useState(false)
    const handleCloseCancellationReason = () => {
        setCencellationReason(false)
    }
    const handleShowCancellationReason = () => {
        setCencellationReason(true)
    }
    const [cancellationReasonEdit, setCencellationReasonEdit] = useState(false)
    const handleCloseCancellationReasonEdit = () => {
        setCencellationReasonEdit(false)
    }
    const handleShowCancellationReasonEdit = () => {
        setCencellationReasonEdit(true)
    }
    const headerheaderCancellation = [{ name: "Reason" }, { name: "Cancellation Type" }, { name: "Action" }]
    const [deleteVehicle, setDeleteVehicle] = useState(false)
    const handleShowDeleteVehicle = () => {
        setDeleteVehicle(true)
    }
    const handleCloseDeleteVehicle = () => {
        setDeleteVehicle(false)
    }
    const editIcon = <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <path d="M5.75977 24.2399L7.25977 18.2999L16.1998 9.35986L20.6398 13.7999L11.6998 22.7399L5.75977 24.2399ZM8.33977 18.8999L7.43977 22.5599L11.0998 21.6599L18.9598 13.7999L16.1998 11.0399L8.33977 18.8999Z" fill="#FF65C3" />
        <path d="M10.6801 22.3802C10.3201 20.8802 9.12012 19.6802 7.62012 19.3202L7.92012 18.1802C9.84012 18.6602 11.3401 20.1602 11.8201 22.0802L10.6801 22.3802Z" fill="#FF65C3" />
        <path d="M17.5784 11.5724L18.4268 12.4208L10.6208 20.2328L9.77241 19.3868L17.5784 11.5724ZM6.59961 23.4002L8.33961 22.9802C8.15961 22.3202 7.67961 21.8402 7.01961 21.6602L6.59961 23.4002ZM20.9996 13.4402L16.5596 9.0002L18.3596 7.2002L18.6596 7.2602C20.8196 7.5602 22.4996 9.2402 22.7996 11.4002L22.8596 11.7002L20.9996 13.4402ZM18.2396 9.0002L20.9996 11.7602L21.5396 11.2202C21.2396 9.8402 20.1596 8.7602 18.7796 8.4602L18.2396 9.0002Z" fill="#FF65C3" />
    </svg>
    const deletIcon = <svg className='ms-4' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M7 21C6.45 21 5.979 20.804 5.587 20.412C5.195 20.02 4.99933 19.5493 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.804 20.021 18.412 20.413C18.02 20.805 17.5493 21.0007 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="#FF65C3" />
    </svg>

    // const handle_delete = () => {
    //     handleShowDeleteVehicle()
    // }

    // const delete_record = () => {
    //     dispatch(delete_cancelation_reason({ vehicle_id }))
    // }

    return (
        <div>
            <div className='d-flex justify-content-between mb-5 cancellation_navs pt-4'>
                <select name="" id="" onChange={(e) => setReason_type(e.target.value)} value={reason_type}>
                    <option value="driver">Driver</option>
                    <option value="passenger">Passenger</option>
                </select>
                <Button title="Add Reason" className="outline_btn" onClick={handleShowCancellationReason} />
            </div>
            <DataTable header={headerheaderCancellation} loading={all_reasons?.loading} val={true}>
                {reasons?.map((data, index) => {
                    return (
                        <tr key={index}>
                            <td style={{ maxWidth: "600px", paddingRight: "30px" }}>
                                {data?.cancellationReason}
                            </td>
                            <td>
                                {data?.type}
                            </td>

                            <td>
                                <span onClick={() => { handleShowCancellationReasonEdit(); setCancelation_id(data?._id) }}>{editIcon}</span>
                                {/*  <span onClick={() => { handle_delete(); setVehicle_id(data?._id) }}>{deletIcon}</span> */}
                            </td>
                        </tr>
                    )
                })}

            </DataTable>
            <CommonModal title="Cancellation Reason" handleClose={handleCloseCancellationReason} show={cancellationReason}>
                {/* <AddVehicleModal  handleClose={handleCloseCancellationReason} /> */}
                <ReasonModal handleClose={handleCloseCancellationReason} get_all_reasons_data={get_all_reasons_data} />
            </CommonModal>

            <CommonModal show={cancellationReasonEdit} handleClose={handleCloseCancellationReasonEdit} title="Edit vehicle" >
                <EditReasonModal handleClose={handleCloseCancellationReasonEdit} cancelation_id={cancelation_id} get_all_reasons_data={get_all_reasons_data} />
            </CommonModal>
            {/* <CommonModal size="md" show={deleteVehicle} handleClose={handleCloseDeleteVehicle} className="confirmodal">
                <ConfirmModal confirmMessage="Are you sure? you want to delete this" handleClose={handleCloseDeleteVehicle} delete_record={delete_record} loading={delete_result?.loading} />
            </CommonModal> */}
        </div>
    )
}

export default CancellationTab