import React, { useState } from 'react'
import Wrapper from '../Components/Wrapper/wrapper'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import RiderWalletTab from '../Components/Tabs/riderWalletTab';
import DriverWalletTab from '../Components/Tabs/driverWalletTab';
import './walletHistory.css'
const Wallet = () => {
  const [selectedTab, setSelectedTab] = useState('riders');
  const [driver, setDriver] = useState(false)
  const [passenger, setPassenger] = useState(true)
  const handleTabSelect = (eventKey) => {
    setSelectedTab(eventKey);
    if (eventKey === 'riders') {
      setPassenger(true)
      setDriver(false)
    } else if (eventKey === 'drivers') {
      setPassenger(false)
      setDriver(true)
    }
  };
  return (
    <Wrapper title="Wallet">
      <Tabs activeKey={selectedTab} onSelect={handleTabSelect} defaultActiveKey="riders"
        id="uncontrolled-tab-example"
        className="mb-3">
        <Tab eventKey="riders" title="Riders">
          <RiderWalletTab passenger={passenger} />
        </Tab>
        <Tab eventKey="drivers" title="Drivers">
          <DriverWalletTab driver={driver} />
        </Tab>
      </Tabs>
    </Wrapper>
  )
}

export default Wallet