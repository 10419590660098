import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const delete_selected_vehicle = createAsyncThunk("delete_selected_vehicle", async ({ vehicle_id }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
        method: 'DELETE',
        redirect: 'follow',
        headers: myHeaders,
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/vehicleTypes/${vehicle_id}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const delete_selected_vehicle_detail = createSlice({
    name: "delete_selected_vehicle_detail",
    initialState: {
        data: "",
        isError: false,
        isSuccess: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_delete_vehicle_State: (state) => {
            state.data = "";
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(delete_selected_vehicle.pending, (state) => {
            state.loading = true
        })
            .addCase(delete_selected_vehicle.fulfilled, (state, action) => {
                state.data = action.payload
                state.isError = false
                state.isSuccess = true
                state.loading = false
            })
            .addCase(delete_selected_vehicle.rejected, (state, action) => {
                state.loading = false
                state.isError = true
                state.isSuccess = false
                state.error = action.payload
            })
    }
})
export const { clear_delete_vehicle_State } = delete_selected_vehicle_detail.actions;
export default delete_selected_vehicle_detail.reducer