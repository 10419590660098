import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export const VerifyAuth = ({ children }) => {
    const token = localStorage.getItem("token");

    if (token) {
        try {
            const decodedToken = jwtDecode(token);
            if (decodedToken.exp < Date.now() / 1000) {
                return <Navigate to="/" />;
            } else {
                return children;
            }
        } catch (error) {
            return <Navigate to="/" />;
        }
    } else {
        return <Navigate to="/" />;
    }
}