import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// export const add_vehicle_data = createAsyncThunk("add_vehicle_data", async ({ vehicle_type, day_price, night_price, seat_capacity,vehicleIcon }, thunkAPI) => {
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

//     var formdata = new FormData();
//     formdata.append("type", vehicle_type);
//     formdata.append("dayPrice", day_price);
//     formdata.append("nightPrice", night_price);
//     formdata.append("seatingCapacity", seat_capacity);
//     formdata.append("vehicleIcon", vehicleIcon);

//     var requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: formdata,
//         redirect: 'follow'
//     };
//     try {
//         const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/vehicleTypes`, requestOptions)
//         if (!response.ok) {
//             const errorMessage = await response.json();
//             const statusCode = response.status;
//             if (errorMessage) {
//                 throw new Error(errorMessage.message);
//             }
//         }
//         const result = await response.json();
//         return result;

//     } catch (error) {
//         return thunkAPI.rejectWithValue({
//             message: error.message,
//         });
//     }
// })

export const add_vehicle_data = createAsyncThunk(
    'add_vehicle_data',
    async ({ vehicle_type, day_price, night_price, seat_capacity, vehicleIcon }, thunkAPI) => {
      const token = localStorage.getItem('token');
  
      const formData = new FormData();
      formData.append('type', vehicle_type);
      formData.append('dayPrice', day_price);
      formData.append('nightPrice', night_price);
      formData.append('seatingCapacity', seat_capacity);
      formData.append('vehicleIcon', vehicleIcon);
  
      const requestOptions = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        redirect: 'follow',
      };
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/vehicleTypes`, requestOptions);
  
        if (!response.ok) {
          const errorMessage = await response.json();
          throw new Error(errorMessage.message);
        }
  
        const result = await response.json();
        return result;
      } catch (error) {
        return thunkAPI.rejectWithValue({
          message: error.message,
        });
      }
    }
  );

export const add_vehicle = createSlice({
    name: "add_vehicle",
    initialState: {
        data: {},
        isSuccess: false,
        isError: false,
        error: null,
        loading: false
    },
    reducers: {
        clear_add_vehicle_State: (state) => {
            state.data = {};
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(add_vehicle_data.pending, (state) => {
            state.loading = true
        })
            .addCase(add_vehicle_data.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
            })
            .addCase(add_vehicle_data.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
            })
    }
})
export const { clear_add_vehicle_State } = add_vehicle.actions;
export default add_vehicle.reducer