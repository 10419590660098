import React from 'react'
import './card.css'
const Card = ({children,className}) => {
  return (
    <div className={`cmn_card ${className}`}>
        {children}
    </div>
  )
}

export default Card