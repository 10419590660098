import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const get_all_driver_request = createAsyncThunk("get_all_driver_request", async ({ token, page, sort, coloum }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/getDriverRequest?page=${page}&sort=${sort}&column=${coloum}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }

})

export const get_driver_details = createSlice({
    name: "get_driver_details",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null,
    },
    reducers: {
        clear_driver_details_State: (state) => {
            state.data = [];
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(get_all_driver_request.pending, (state) => {
                state.loading = true;
                state.isSuccess = false;
                state.isError = false;
                state.error = null;
            })
            .addCase(get_all_driver_request.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
                state.isSuccess = true;
                state.isError = false;
                state.error = null;
            })
            .addCase(get_all_driver_request.rejected, (state, action) => {
                state.loading = false;
                state.isSuccess = false;
                state.isError = true;
                state.error = action.payload;
            });
    },
})
export const { clear_driver_details_State } = get_driver_details.actions;
export default get_driver_details.reducer;