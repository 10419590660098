import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const user_login = createAsyncThunk("login", async ({ email, password, mobile_number }, thunkAPI) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: email ? email : null,
      password: password,
      number: mobile_number ? mobile_number : null,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/login`, requestOptions);

    if (!response.ok) {
      const errorMessage = await response.json();
      const statusCode = response.status;
      if (errorMessage) {
        throw new Error(errorMessage.message);
    }
    }

    const result = await response.json();

    // Check if the response contains an 'error' property indicating an error from the API
    if (result.error) {
      throw new Error(result.error);
    }

    // Store the token in localStorage
    localStorage.setItem("token", result?.data?.token);

    return result;
  } catch (error) {
    // Handle errors and return them to the component using rejectWithValue
    return thunkAPI.rejectWithValue({
      message: error.message,
    });
  }
});


export const LoginData = createSlice({
  name: "LoginData",
  initialState: {
    data: [],
    loading: false,
    error: null,
    isSuccess: false,
    isError: false
  },
  reducers: {
    clear_login_state: (state) => {
      state.data = [];
      state.loading = false;
      state.error = null;
      state.isSuccess = false;
      state.isError = false;
      return state;
    }
  },
  extraReducers: {
    [user_login.pending]: (state) => {
      state.loading = true;
    },
    [user_login.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.isSuccess = true
    },
    [user_login.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.isError = true
    },
  },
});

export const { clear_login_state } = LoginData.actions;
export default LoginData.reducer
