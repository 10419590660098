import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const get_all_drivers = createAsyncThunk("get_all_drivers", async ({ coloum, page, search, sort, rating }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
        "sort": sort,
        "search": search,
        "column": coloum,
        "ratings":rating
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/getAllDrivers?page=${page}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const all_driver_data = createSlice({
    name: "all_driver_data",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        error: null,
        loading: false
    },
    reducers: {
        clear_all_driver_details_State: (state) => {
            state.data = [];
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(get_all_drivers.pending, (state) => {
                state.loading = true;
            })
            .addCase(get_all_drivers.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
                state.isSuccess = true;
                state.isError = false;
            })
            .addCase(get_all_drivers.rejected, (state, action) => {
                state.loading = false;
                state.isError = true;
                state.error = action.payload;
            });
    },

})
export const { clear_all_driver_details_State } = all_driver_data.actions;
export default all_driver_data.reducer