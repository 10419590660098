import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Button from '../Components/Button/button'
import './login.css'
import { useDispatch, useSelector } from 'react-redux'
import { user_login } from '../features/Login/loginSlice'
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { clear_login_state } from '../features/Login/loginSlice'
const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const data = useSelector((state) => { return state.login })
  const [mobile_number, setMobile_number] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate("/dashboard")
    }
  }, [navigate])

  const handle_email_and_mobile = (e) => {
    if (/[a-zA-Z]/.test(e.target.value)) {
      setEmail(e.target.value)
    } else {
      setMobile_number(e.target.value);
    }
  }
  useEffect(() => {
    if (data?.isError && data?.error?.message) {
      toast.error(data?.error?.message)
      setEmail("")
      setPassword("")
      dispatch(clear_login_state())
    }
    if (data?.loading) {
      setLoading(true)
    } else {
      setLoading(false)
    }
    if (data?.isSuccess) {
      dispatch(clear_login_state())
    }
  }, [data])

  // this below function is used to login with email and mobile as well
  const handle_login = (event) => {
    event.preventDefault()
    if (email && !email?.includes("@")) {
      toast.error("Please Enter a Valid Email")
      setEmail("")
      setPassword("")
    } else {
      dispatch(user_login({ email, password, mobile_number }))
    }
  }

  useEffect(() => {
    data?.isSuccess && navigate("/dashboard")
    data?.isSuccess && toast.success("Logged In")
  }, [data?.isSuccess])

  return (
    <Row className='m-0'>
      <Col lg={6} className='p-0'>
        <div className='Login_wrapper login_img_section'>
          <img src="/images/ladygo_logo.png" alt="" />
          <img src="/images/login.png" alt="" />
        </div>
      </Col>
      <Col lg={6} className='p-0'>
        <div className='Login_wrapper'>
          <div className='login_form'>
            <div className='text-center mb-3'>
              <img src="/images/ladygo_logo.png" alt="" />
            </div>
            <div className='login_wrapper'>
              <h2>Log In to Admin Panel</h2>
              <p>Enter your phone number and password below</p>
              <form action="" >
                <div className='form-group mb-3'>
                  <label htmlFor="">PHONE NUMBER / EMAIL ADDRESS</label>
                  <input type="text" name="name" autoComplete="off" className='form-control' placeholder='Enter your phone number' value={mobile_number ? mobile_number : email} onChange={(e) => handle_email_and_mobile(e)} />
                </div>
                <div className='form-group mb-3'>
                  <label htmlFor="">PASSWORD</label>
                  <input type="password" name="password" autoComplete="off" className='form-control' placeholder='Enter your password' value={password} onChange={(e) => setPassword(e.target.value)} />
                  <p className='forgot_pass mt-2 text-end' onClick={() => navigate("/forgot-password", { state: { forget_state: true } })}>Forgot Password?</p>
                </div>
                <div className='login_navs'>
                  <Button onClick={(e) => handle_login(e)} title="LOGIN" className="theme_light w-100 mb-2" loading={loading} />

                  <Button title="LOG IN WITH OTP" className="theme_dark w-100" />
                </div>
                <div className='mt-3'>
                  <p className='existing_account text-center'>Do you have an Account ? <Link className='ms-2'>Sign up</Link></p>
                </div>
              </form>
            </div>
          </div>

        </div>
      </Col>
    </Row>
  )
}

export default Login