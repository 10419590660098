import React, { useState, useEffect } from 'react'
import Wrapper from '../Components/Wrapper/wrapper'
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { selected_driver_details } from '../features/AllDrivers/get_selected_driver_detail_slice';
import Skeleton from 'react-loading-skeleton'
import { toast } from "react-hot-toast";
import moment from 'moment';
import Loader from '../Components/Loader'
import Rating from '../Components/Rating/rating';
import { get_all_currencies } from '../features/all_currency/all_currency_slice';
import { activate_deactivate_selected_driver } from '../features/AllDrivers/activate_deactivate_driver_slice';
import { clear_activate_deactivate_driver_State } from '../features/AllDrivers/activate_deactivate_driver_slice';
import Slider from "react-slick";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { update_driver_currency } from '../features/AllDrivers/update_slice';
import { clear_update_currency_state } from '../features/AllDrivers/update_slice';
import { IoIosArrowBack } from "react-icons/io";
import Moment from 'react-moment';
import Button from '../Components/Button/button';
import { updateTheDriverCommission } from '../features/AllDrivers/updateDriverCommison';
import { clear_driver_commission_state } from '../features/AllDrivers/updateDriverCommison';


const DriverDetails = ({ children }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { id } = location?.state ? location?.state : location;
    const driver_detail = useSelector((state) => { return state.selected_driver_detail })
    console.log(driver_detail, "this is the driver details")
    const currency = useSelector((state) => { return state.all_currency })
    const activate_driver = useSelector((state) => { return state.active_deactive_driver })
    const loading = driver_detail?.loading
    const currency_update = useSelector((state) => { return state.update_currency_driver })
    const [driver_currency, setDriver_currency] = useState("")
    const driver_commission = useSelector((state) => { return state.driver_commission })
    console.log(driver_commission, "this is the driver commission")
    useEffect(() => {
        if (id === undefined) {
            navigate("/drivers")
        }
    }, [id])

    useEffect(() => {
        dispatch(selected_driver_details({ id }))
        dispatch(get_all_currencies())
    }, [])

    useEffect(() => {
        if (driver_detail?.isError && driver_detail?.error?.message !== "") {
            toast.error(driver_detail?.error?.message)
            navigate("/drivers")
        }
    }, [driver_detail?.isError])

    const handle_activate_driver = () => {
        dispatch(activate_deactivate_selected_driver({ id }))
    }

    useEffect(() => {
        if (activate_driver?.data?.type === "success") {
            toast.success(activate_driver?.data?.message)
            dispatch(clear_activate_deactivate_driver_State())
            dispatch(selected_driver_details({ id }))
        }
    }, [activate_driver])

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        dispatch(update_driver_currency({ id, driver_currency }))
        dispatch(selected_driver_details({ id }))
    }, [driver_currency])

    useEffect(() => {
        if (currency_update?.isSuccess) {
            dispatch(clear_update_currency_state())
        }
    }, [currency_update])

    useEffect(() => {
        if (driver_commission?.isSuccess) {
            toast.success(driver_commission?.message?.message)
            dispatch(clear_driver_commission_state())
            dispatch(selected_driver_details({ id }))
        }
        if (driver_commission?.isError) {
            toast.error(driver_commission?.error?.message)
            dispatch(clear_driver_commission_state())
        }
    }, [driver_commission])


    const formatKey = (key) => {
        return key
            .replace(/([A-Z])/g, ' $1') // Add a space before each capital letter
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };


    return (
        <Wrapper title="Drivers Detail ">
            {loading ? <Loader />
                :
                (<div className='driver_info_card'>
                    <div className='driver_header'>
                        <div className='drive_info'>
                            <span className='mt-2' onClick={() => navigate("/drivers")} style={{ cursor: "pointer" }} title="Back To Drivers"><IoIosArrowBack size={20} /></span>


                            <div className='driver_picture' style={{ cursor: "pointer" }} title="View Profile Image">
                                <PhotoProvider maskOpacity={0.7} speed={() => 800}
                                    easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
                                >
                                    <PhotoView src={driver_detail?.data?.driverdata?.driverImage}>
                                        <img src={driver_detail?.data?.driverdata?.driverImage} alt="user" />
                                    </PhotoView>
                                </PhotoProvider>
                                {driver_detail?.data?.driverdata?.onlineStatus === "online" ? <img className='verify' src="/images/verified.png" /> : <img className='verify' src="/images/unverifed.png" />}
                            </div>

                            <div>
                                <li>
                                    <h6>
                                        {driver_detail?.data?.driverdata?.driverFirstName + " " + driver_detail?.data?.driverdata?.driverLastName}
                                        {driver_detail?.data?.driverdata?.isverified ? <img className='verify_image' src="/images/verifybadge.png" /> : <img className='verify_image' src="/images/notverifiedbadge.png" />}
                                    </h6>
                                </li>
                                <ul className='d-flex mb-0 rating'>
                                    <li className='d-flex'><Rating value={driver_detail?.data?.driverdata?.ratings ? driver_detail?.data?.driverdata?.ratings : 0} /></li>
                                    <li>({driver_detail?.data?.driverdata?.reviewsCount} Review)</li>
                                </ul>
                            </div>

                        </div>
                        <div className='driver_filter'>
                            <Button onClick={() => navigate("/wallet/walletHistory", { state: { user_id: id, type: "driver" } })} title="Add Credit/Debit" className="outline_btn" />
                            <select onChange={(e) => setDriver_currency(e.target.value)}>
                                <option>{driver_detail?.data?.driverdata?.currency ? driver_detail?.data?.driverdata?.currency : "Select Currency"}</option>
                                {
                                    currency?.data?.rates && Object.keys(currency?.data?.rates)?.map((data, index) => {
                                        return (
                                            <option value={data} key={index}>{data}</option>
                                        );
                                    })
                                }

                            </select>
                            <div className='enable_driver'>
                                {driver_detail?.data?.driverdata?.active ? <span className='d-block'>
                                    Active
                                </span> : <span className='d-block' style={{ color: "grey" }}>
                                    InActive
                                </span>}
                                <label class="switch">
                                    <input type="checkbox" onChange={handle_activate_driver} checked={driver_detail?.data?.driverdata?.active} />
                                    <span class="slider_switch"></span>
                                </label>
                            </div>
                            <div className='enable_driver'>
                                {driver_detail?.data?.driverdata?.commissionStatus ? <span className='d-block'>
                                    Commission
                                </span> : <span className='d-block' style={{ color: "grey" }}>
                                    Commission
                                </span>}
                                <label class="switch">
                                    <input type="checkbox" onChange={() => {
                                        dispatch(updateTheDriverCommission({ id }))
                                    }} checked={driver_detail?.data?.driverdata?.commissionStatus} />
                                    <span class="slider_switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='driver_info_list'>
                        <Row className='m-0'>

                            <Col lg={5}>
                                <ul className='mb-0'>
                                    <li>
                                        <p className='mb-1'>Driver ID</p>
                                        <strong>{id}</strong>
                                        <p className='mb-1 mt-3'>Driver Address</p>
                                        <strong>{driver_detail?.data?.driverdata?.driverAddress ? driver_detail?.data?.driverdata?.driverAddress : "Not Available"}</strong>
                                        {/* <p className='mb-1 mt-3'> Driving License Number</p> */}
                                        {/* {loading ? <Skeleton width={200} height={30} /> : <strong>{driver_detail?.data?.driverdata?.drivingLicenceNumber ? driver_detail?.data?.driverdata?.drivingLicenceNumber : "Not Available"}</strong>} */}
                                    </li>
                                    <li>
                                        <p className='mb-1'>Mobile Number</p>
                                        <strong>+{driver_detail?.data?.driverdata?.mobileNumber ? driver_detail?.data?.driverdata?.mobileNumber : "Not Available"}</strong>
                                        <p className='mb-1 mt-3'>City Drive In</p>
                                        <strong>{driver_detail?.data?.driverdata?.cityDrivein ? driver_detail?.data?.driverdata?.cityDrivein : "Not Available"}</strong>


                                    </li>
                                </ul>
                            </Col>
                            <Col lg={7}>
                                <ul className='mb-0'>
                                    <li>
                                        <p className='mb-1'>E-Mail</p>
                                        <strong>{driver_detail?.data?.driverdata?.email ? driver_detail?.data?.driverdata?.email : "Not Available"}</strong>
                                        <p className='mb-1 mt-3'>Country</p>
                                        <strong>{driver_detail?.data?.driverdata?.country ? driver_detail?.data?.driverdata?.country : "Not Available"}</strong>

                                    </li>

                                    <li>
                                        <p className='mb-1'>Register Date</p>
                                        <strong>  <Moment format=' Do MMMM YYYY, h:mm:ss a'>{driver_detail?.data?.driverdata?.registerDate}</Moment></strong>
                                        <p className='mb-1 mt-3'>Currency</p>
                                        <strong>{driver_detail?.data?.driverdata?.currency ? driver_detail?.data?.driverdata?.currency : "Not Available"}</strong>
                                    </li>
                                </ul>
                            </Col>
                        </Row>

                        <Row className='m-0 mt-5'>
                            <Col lg={12} className='mb-2'>
                                <h5><strong>Car Details:</strong></h5>
                            </Col>
                            <Col lg={5} className='ps-0'>
                                <div className='car_img w-75'>

                                    <PhotoProvider maskOpacity={0.7} speed={() => 800}
                                        easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
                                    >
                                        <Slider {...settings}>
                                            {driver_detail?.data?.driverdata?.carImages &&
                                                driver_detail?.data?.driverdata?.carImages?.map((element, index) => (
                                                    <div className='car_gallery w-100'>
                                                        <PhotoView key={index} src={element} className='img-fluid' alt="" >
                                                            <img src={element} className='img-fluid' alt="" />
                                                        </PhotoView>
                                                    </div>
                                                ))}
                                        </Slider>
                                    </PhotoProvider>
                                </div>
                            </Col>
                            <Col lg={7}>
                                <div className='car_details'>
                                    <ul>
                                        <li>
                                            <p className='mb-1'>Car Name</p>
                                            <strong>{driver_detail?.data?.driverdata?.carName ? driver_detail?.data?.driverdata?.carName : "Not Available"}</strong>
                                        </li>
                                        <li>
                                            <p className='mb-1'>Color</p>
                                            <strong>{driver_detail?.data?.driverdata?.color ? driver_detail?.data?.driverdata?.color : "Not Available"}</strong>
                                        </li>
                                        <li>
                                            <p className='mb-1'>Car Type</p>
                                            <strong>{driver_detail?.data?.driverdata?.carType ? driver_detail?.data?.driverdata?.carType : "Not Available"}</strong>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <p className='mb-1'>Car Number</p>
                                            <strong>{driver_detail?.data?.driverdata?.drivingLicenceNumber ? driver_detail?.data?.driverdata?.drivingLicenceNumber : "Not Available"}</strong>
                                        </li>
                                        <li>
                                            <p className='mb-1'>capacity</p>
                                            <strong>{driver_detail?.data?.driverdata?.capacity ? driver_detail?.data?.driverdata?.capacity : "Not Available"}</strong>
                                        </li>
                                        <li>

                                        </li>
                                    </ul>
                                    <ul>

                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row className='m-0 mt-5'>
                            <Col lg={12} className='mb-2'>
                                <h5><strong>Car Document Images:</strong></h5>
                            </Col>
                            {driver_detail?.data?.driverdata?.carDocumentsImages && Object.entries(driver_detail?.data?.driverdata?.carDocumentsImages).map(([key, value], index) => (
                                <Col lg={3} md={4} sm={6} xs={12} key={index} className='mb-3'>
                                    <div className='car_image'>
                                    {/* <h6>{key.charAt(0).toUpperCase() + key.slice(1)}</h6> */}
                                    <h6>{formatKey(key)}</h6>
                                        <PhotoProvider maskOpacity={0.7} speed={() => 800}
                                            easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
                                        >
                                            <PhotoView src={value}>
                                                <img src={value} className='img-fluid' alt={`Car Document Image ${index + 1}`} />
                                            </PhotoView>
                                        </PhotoProvider>
                                    </div>
                                </Col>
                            ))}
                        </Row>

                    </div>
                </div>)}
        </Wrapper>
    )
}

export default DriverDetails