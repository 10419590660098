import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const update_selected_vehicle_type = createAsyncThunk("update_selected_vehicle_type", async ({ vehicle_id, vehicle_type, day_price, night_price, seat_capacity, vehicleIcon }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var formdata = new FormData();
    formdata.append("vehicleIcon", vehicleIcon);
    formdata.append("type", vehicle_type);
    formdata.append("dayPrice", day_price);
    formdata.append("nightPrice", night_price);
    formdata.append("seatingCapacity", seat_capacity);

    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/vehicleTypes/${vehicle_id}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;

    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const update_vehicle_type = createSlice({
    name: "update_vehicle_type",
    initialState: {
        data: {},
        isSuccess: false,
        isError: false,
        error: null,
        loading: false
    },
    reducers: {
        clear_update_vehicle_State: (state) => {
            state.data = {};
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(update_selected_vehicle_type.pending, (state) => {
            state.loading = true
        })
            .addCase(update_selected_vehicle_type.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isError = false
                state.data = action.payload
                state.loading = false
            })
            .addCase(update_selected_vehicle_type.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
            })
    }
})
export const { clear_update_vehicle_State } = update_vehicle_type.actions;
export default update_vehicle_type.reducer