import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from '../Components/Button/button'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { password_reset } from '../features/resetPassword/resetPasswordSlice'
import { clear_reset_password_state } from '../features/resetPassword/resetPasswordSlice'
import { toast } from "react-hot-toast";
const ResetPassword = () => {
    const dispatch = useDispatch()
    const data = useSelector((state) => { return state.resetPassword })
    const location = useLocation()
    const navigate = useNavigate()
    const { email } = location?.state ? location?.state : location;
    console.log(email,"this is tge email")
    const [new_password, setnew_password] = useState("");
    const [confirm_password, setConfirm_password] = useState("");
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (email === undefined) {
            navigate("/")
        }
    }, [])

    useEffect(() => {
        if (data?.message?.type === "success") {
            toast.success(data?.message?.message)
            navigate("/")
            dispatch(clear_reset_password_state())
        }
        if (data?.isError) {
            toast.error(data?.error?.message)
        }
        if (data?.loading) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [data])

    const handle_change_password = () => {
        if (new_password !== confirm_password) {
            toast.error("Password Doesn't Matched")
        }
        else {
            dispatch(password_reset({ email, new_password, confirm_password }))
        }

    }
    return (
        <Row className='m-0'>
            <Col lg={6} className='p-0'>
                <div className='Login_wrapper login_img_section'>
                    <img src="/images/ladygo_logo.png" alt="" />
                    <img src="/images/login.png" alt="" />
                </div>
            </Col>
            <Col lg={6} className='p-0'>
                <div className='Login_wrapper'>
                    <div className='login_form'>
                        <div className='text-center mb-3'>
                            <img src="/images/ladygo_logo.png" alt="" />
                        </div>
                        <div className='login_wrapper'>
                            <div>
                                <h2>Reset Password</h2>
                                <div className='form-group mb-3'>
                                    <label htmlFor="">ENTER NEW PASSWORD</label>
                                    <input type="password" name="name" autoComplete="off" className='form-control' placeholder='New password' onChange={(e) => setnew_password(e.target.value)} />
                                </div>
                                <div className='form-group mb-3'>
                                    <label htmlFor="">CONFIRM NEW PASSWORD</label>
                                    <input type="password" name="name" autoComplete="off" className='form-control' placeholder='Confirm new password' onChange={(e) => setConfirm_password(e.target.value)} />
                                </div>

                                <div className='login_navs'>
                                    <Button title="Send" className="theme_light w-100 mb-2" loading={loading} onClick={() => handle_change_password()} />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </Col>
        </Row>
    )
}

export default ResetPassword