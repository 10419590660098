import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const activate_deactivate_selected_driver = createAsyncThunk("activate_deactivate_selected_driver", async ({ id }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/changeActiveStatus?id=${id}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            } else {
                throw new Error(`Failed with status ${statusCode}`);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }

})

export const activate_deactivate_driver = createSlice({
    name: "activate_deactivate_driver",
    initialState: {
        data: [],
        isError: false,
        isSuccess: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_activate_deactivate_driver_State: (state) => {
            state.data = [];
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(activate_deactivate_selected_driver.pending, (state) => {
            state.loading = true
        })
            .addCase(activate_deactivate_selected_driver.fulfilled, (state, action) => {
                state.data = action.payload
                state.isError = false
                state.isSuccess = true
                state.loading = false
            })
            .addCase(activate_deactivate_selected_driver.rejected, (state, action) => {
                state.isError = true
                state.error = action.payload
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const { clear_activate_deactivate_driver_State } = activate_deactivate_driver.actions;
export default activate_deactivate_driver.reducer