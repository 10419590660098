import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const update_driver_currency = createAsyncThunk("update_driver_currency", async ({ id, driver_currency }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
        "currency": driver_currency
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/updateCurrency?driverId=${id}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const update_currency = createSlice({
    name: "update_currency",
    initialState: {
        data: [],
        isError: false,
        isSuccess: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_update_currency_state: (state) => {
            state.data = [];
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(update_driver_currency.pending, (state) => {
            state.loading = true
        })
            .addCase(update_driver_currency.fulfilled, (state, action) => {
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
                state.loading = false
            })
            .addCase(update_driver_currency.rejected, (state, action) => {
                state.isError = true
                state.error = action.payload
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const { clear_update_currency_state } = update_currency.actions;
export default update_currency.reducer