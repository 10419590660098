import React, { useEffect, useState } from 'react'
import Button from '../Button/button'
import { useDispatch, useSelector } from 'react-redux'
import { change_admin_password } from '../../features/changePassword/changePasswordSlice'
import { toast } from "react-hot-toast";
import { clear_change_password_state } from '../../features/changePassword/changePasswordSlice';

const ChangePasswordModal = ({ handleClose }) => {
    const dispatch = useDispatch()
    const password_data = useSelector((state) => { return state.change_admin_password })
    const [current_password, setCurrent_password] = useState("")
    const [new_password, setNew_password] = useState("")
    const [confirm_password, setConfirm_password] = useState("")

    const handle_change_password = () => {
        if (new_password === "") {
            toast.error("Please Enter New Password!")
        }
        else if (current_password === "") {
            toast.error("Please Enter Current Password!")
        }
        else if (confirm_password === "") {
            toast.error("Please Enter Confirm Password!")
        }
        else if (new_password !== confirm_password) {
            toast.error("New Password and Confirm Password doesn't matched")
        }
        else {
            dispatch(change_admin_password({ current_password, new_password, confirm_password }))
        }
    }

    useEffect(() => {
        if (password_data?.isError) {
            toast.error(password_data?.error?.message)
            dispatch(clear_change_password_state())
        }
        if (password_data?.isSuccess) {
            handleClose()
            toast.success("Password Changed SuccessFully !!!!")
            dispatch(clear_change_password_state())
        }
    }, [password_data])
    return (
        <div className='login_wrapper'>
            <div class="form-group mb-3">
                <label className='mt-0' for="">Current Password</label>
                <input type="password" name="name" autocomplete="off" class="form-control" placeholder="******" value={current_password} onChange={(e) => setCurrent_password(e.target.value)} />

            </div>
            <div class="form-group mb-3">
                <label for="">New Password</label>
                <input type="password" name="name" autocomplete="off" class="form-control" placeholder="******" value={new_password} onChange={(e) => setNew_password(e.target.value)} />

            </div>
            <div class="form-group mb-3">
                <label for="">Confirm Password</label>
                <input type="password" name="name" autocomplete="off" class="form-control" placeholder="******" value={confirm_password} onChange={(e) => setConfirm_password(e.target.value)} />

            </div>
            <div className='text-end pt-2'>
                <Button className="theme_light me-3" title="Change Password" onClick={() => handle_change_password()} loading={password_data?.loading}/>
                <Button className="outline_btn " title="Cancel" onClick={handleClose} />
            </div>

        </div>
    )
}

export default ChangePasswordModal