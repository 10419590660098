import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const get_all_riders = createAsyncThunk("get_all_riders", async ({ coloum, page, search,sort }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
        "sort": sort,
        "search": search,
        "column": coloum
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}api/v1/getpassengers?page=${page}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const all_riders = createSlice({
    name: "all_riders",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_all_rider_details_State: (state) => {
            state.data = [];
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(get_all_riders.pending, (state) => {
            state.loading = true
        })
            .addCase(get_all_riders.fulfilled, (state, action) => {
                state.data = action.payload
                state.isError = false
                state.isSuccess = true
                state.loading = false
            })
            .addCase(get_all_riders.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const { clear_all_rider_details_State } = all_riders.actions;
export default all_riders.reducer