import React, { useEffect, useState } from 'react'
import './modalbody.css'
import Button from '../Button/button'
import { useDispatch, useSelector } from 'react-redux';
import { view_driver_all_documents } from '../../features/Driver_details/driver_veiw_documents_slice';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { verify_the_driver } from '../../features/Driver_details/driver_verify_slice';
import { toast } from "react-hot-toast";
import { clear_driver_verified_state } from '../../features/Driver_details/driver_verify_slice';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import Loader from "../Loader"
import { useNavigate } from 'react-router';

const RequestModal = ({ id, all_driver_request, handleClose }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const driver_data = useSelector((state) => { return state.driver_documents })
    const verify_message = useSelector((state) => { return state.verify_driver })
    const token = localStorage.getItem('token')
    const [accept_loading, setAccept_loading] = useState(false)
    const [reject_loading, setReject_loading] = useState(false)

    useEffect(() => {
        return () => {
            dispatch(clear_driver_verified_state())
        };
    }, [])

    useEffect(() => {
        if (verify_message?.isSuccess) {
            toast.success(verify_message?.message?.message)
            all_driver_request()
            navigate("/drivers")
            dispatch(clear_driver_verified_state())
        }
    }, [verify_message?.isSuccess])

    useEffect(() => {
        if (verify_message?.isError) {
            toast.success(verify_message?.message?.message)
            dispatch(clear_driver_verified_state())
        }
    }, [verify_message?.isError])

    useEffect(() => {
        dispatch(view_driver_all_documents({ id, token }))
    }, [])

    const handle_verify = (type) => {
        type === "accept" ? setAccept_loading(true) : setAccept_loading(false)
        type === "reject" ? setReject_loading(true) : setReject_loading(false)
        dispatch(verify_the_driver({ type, id, token }))
    }

    return (
        <div>
            {driver_data?.loading ?
                <Loader />
                :
                <>
                    <h3 className='detail_title'>Driver Details</h3>
                    <div className='driver_request_details'>
                        <div className='driver_image'>
                            <img src={driver_data?.data?.data?.driverPersonalDetails?.driverImage ? driver_data?.data?.data?.driverPersonalDetails?.driverImage : "https://cdn.pixabay.com/photo/2014/03/25/16/54/user-297566_640.png"} width={120} alt="" />
                        </div>
                        <div className='w-100'>
                            <ul>
                                <li>
                                    <h3><strong>Driver Name</strong></h3>
                                    <h3>{driver_data?.data?.data?.driverPersonalDetails?.firstName + " " + driver_data?.data?.data?.driverPersonalDetails?.lastName}</h3>
                                </li>
                                <li>
                                    <h3><strong>Mobile Number</strong></h3>
                                    <h3>{driver_data?.data?.data?.mobileNumber ? driver_data?.data?.data?.mobileNumber : "Not Available"}</h3>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <h3><strong>Email</strong></h3>
                                    <h3>{driver_data?.data?.data?.email ? driver_data?.data?.data?.email : "Not Available"}</h3>
                                </li>
                                <li>
                                    <h3><strong>Driver Address</strong></h3>
                                    <h3>{driver_data?.data?.data?.driverPersonalDetails?.city ? driver_data?.data?.data?.driverPersonalDetails?.city + driver_data?.data?.data?.driverPersonalDetails?.country : "Not Available"}</h3>
                                </li>
                                {/* <li>
                              <h3><strong>Driver Licsence Number</strong></h3>
                              <h3>{driver_data?.data?.data?.email ? driver_data?.data?.data?.email : "Not Available"}</h3>
                          </li> */}
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <h3 className='detail_title'>Car Details</h3>
                    <div className='driver_request_details d-block'>
                        <ul>
                            <li>
                                <h3><strong>Car Name</strong></h3>
                                <h3>{driver_data?.data?.data?.vehicleDetails?.model ? driver_data?.data?.data?.vehicleDetails?.model : "Not Available"}</h3>
                            </li>
                            <li>
                                <h3><strong>Manufacturer Year</strong></h3>
                                <h3>{driver_data?.data?.data?.vehicleDetails?.vehicleYear ? driver_data?.data?.data?.vehicleDetails?.vehicleYear : "Not Available"}</h3>
                            </li>
                            <li>
                                <h3><strong>Car Manufacturer</strong></h3>
                                <h3>{driver_data?.data?.data?.vehicleDetails?.manufacturer ? driver_data?.data?.data?.vehicleDetails?.manufacturer : "Not Available"}</h3>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <h3><strong>Car Number</strong></h3>
                                <h3>{driver_data?.data?.data?.vehicleDetails?.plateNumber ? driver_data?.data?.data?.vehicleDetails?.plateNumber : "Not Available"}</h3>
                            </li>
                            {/* <li>
                          <h3><strong>Car Fuel Type</strong></h3>
                          <h3>Petrol</h3>
                      </li> */}
                            <li>
                                <h3><strong>Color</strong></h3>
                                <h3>{driver_data?.data?.data?.vehicleDetails?.color ? driver_data?.data?.data?.vehicleDetails?.color : "Not Available"}</h3>
                            </li>
                            <li>
                                <h3><strong>Vehicle Type</strong></h3>
                                <h3>{driver_data?.data?.data?.vehicleType ? driver_data?.data?.data?.vehicleType : "Not Available"}</h3>
                            </li>
                        </ul>
                    </div>
                    <hr />
                    <h3 className='detail_title'>Car Documents</h3>
                    <PhotoProvider maskOpacity={0.7} speed={() => 800}
                        easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
                    >
                        <ul className='cardoc_list'>
                            <li> <PhotoView src={driver_data?.data?.data?.legalDetails?.carRegistrationDocImage}>
                                <img src={driver_data?.data?.data?.legalDetails?.carRegistrationDocImage} alt="" />
                            </PhotoView>
                                <p>Registration Certificate</p> </li>

                            <li> <PhotoView src={driver_data?.data?.data?.legalDetails?.drivingLicenseImageBack}>
                                <img src={driver_data?.data?.data?.legalDetails?.drivingLicenseImageBack} alt="" />
                            </PhotoView>
                                <p>Licsence Back Image</p> </li>
                            <li> <PhotoView src={driver_data?.data?.data?.legalDetails?.drivingLicenseImageFront}>
                                <img src={driver_data?.data?.data?.legalDetails?.drivingLicenseImageFront} alt="" />
                            </PhotoView>
                                <p>Licsence Front Image</p> </li>
                            <li> <PhotoView src={driver_data?.data?.data?.legalDetails?.insuranceImage}>
                                <img src={driver_data?.data?.data?.legalDetails?.insuranceImage} alt="" />
                            </PhotoView>
                                <p>Insurance</p> </li>
                            <li> <PhotoView src={driver_data?.data?.data?.legalDetails?.personalIdCardImageBack}>
                                <img src={driver_data?.data?.data?.legalDetails?.personalIdCardImageBack} alt="" />
                            </PhotoView>
                                <p>ID Card Back Image</p> </li>
                            <li> <PhotoView src={driver_data?.data?.data?.legalDetails?.personalIdCardImageFront}>
                                <img src={driver_data?.data?.data?.legalDetails?.personalIdCardImageFront} alt="" />
                            </PhotoView>
                                <p>ID Card Front Image</p> </li>
                        </ul>
                    </PhotoProvider>

                    <hr />
                    <h3 className='detail_title'>Car Images</h3>
                    <ul className='cardoc_list'>
                        {driver_data?.data?.data?.vehicleDetails?.vehicleImages?.map((item, index) => {
                            return (
                                <li key={index}>
                                    <PhotoProvider>
                                        <PhotoView src={item}>
                                            <img src={item} alt="" />
                                        </PhotoView>
                                    </PhotoProvider>
                                </li>
                            )
                        })}

                    </ul>
                    <hr />
                    <div className='text-center'>
                        <Button className={'outline_btn me-3'} title="Reject" onClick={() => handle_verify("reject")} loading={reject_loading} />
                        <Button className={'theme_light'} title="Accept" onClick={() => handle_verify("accept")} loading={accept_loading} />
                    </div>
                </>
            }
        </div >
    )
}

export default RequestModal
